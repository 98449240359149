import React, { useState } from 'react';
import { DateTime } from 'luxon';
import axios from 'axios';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import StaffContenu from './StaffContenu';
import useInterface from '../../Lib/useInterface';
import EditNotificationMessage from './EditNotificationMessage';
import Button from '../../components/UI/Button';
import ButtonAjouter from '../../components/UI/ButtonAjouter';
import Fieldset from '../../components/UI/Form/Fieldset';

const Notifications = () => {
  const { sendRestErrorMessage, sendMessage } = useInterface();

  return (
    <StaffContenu titre="Send a notification">

      <Formik
        initialValues={{
          message: '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await axios.post('staff/notifications', values);
            sendMessage('The notification has been sent');
          } catch (err) {
            sendRestErrorMessage(err);
            setSubmitting(false);
          }
        }}

        validationSchema={Yup.object().shape({
          message: Yup.string().required('Please provide your message'),
        })}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form className="stdForm form-100">
            <Fieldset type="message" placeholder="Enter a message for all users" name="message" />
            <Button type="submit">Send</Button>

          </Form>
        )}
      </Formik>

    </StaffContenu>
  );
};

export default Notifications;
