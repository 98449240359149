import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import { getCodeFromVimeoUrl, getCodeFromYoutubeUrl } from '../../Lib/misc/youtubeMisc';

const Video = ({
  url, variant, autoPlay, onClickIntercept, onFinish, children, ...props
}) => {
  let component = null;

  if (url.search('vimeo') > -1) {
    component = (
      <Vimeo
        video={url}
        autoplay={autoPlay}
        width="640"
        height="500"
        onEnd={onFinish}
      />
    );
  } else {
    component = (
      <YouTube
        videoId={getCodeFromYoutubeUrl(url)}
        onEnd={onFinish}
        opts={{
          width: '640',
          height: '500',
          playerVars: {
            autoplay: autoPlay,
            allowFullScreen: true,
          },
        }}
      />
    );
  }

  return (
    <div
      className={clsx(
        'stdVideo',
        variant && variant.split(' ').map((el) => `stdVideo-${el}`),
      )}
    >
      { onClickIntercept && <button className="stdVideo-onClickIntercept" type="button" onClick={onClickIntercept} />}
      {component}
      {children && <div className="stdVideo-description">{children}</div>}
    </div>
  );
};

Video.propTypes = {
  url: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  children: PropTypes.string,
  variant: PropTypes.string,
  onFinish: PropTypes.func,
  onClickIntercept: PropTypes.func,
};

Video.defaultProps = {
  autoPlay: false,
  variant: null,
  children: null,
  onClickIntercept: null,
  onFinish: () => {},
};

export default Video;
