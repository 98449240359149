import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ButtonClose from '../../../components/UI/ButtonClose';

const HeaderMenu = ({
  titre, topContent, iconContent, children, setOnCloseCallback, className, onOpen,
}) => {
  const refParent = useRef();

  const [bAct, setBAct] = useState(false);

  const handleClick = (e) => {
    if (bAct && refParent.current) {
      if (!refParent.current.contains(e.target) && (e.target && e.target.parent && !e.target.parent('#portalContainer').length)) {
        setBAct(false);
      }
    }
  };

  useEffect(() => {
    setOnCloseCallback(() => { setBAct(false); });
    document.addEventListener('mousedown', handleClick);

    return () => {
      setOnCloseCallback(null);
      document.removeEventListener('mousedown', handleClick);
    };
  }, [bAct]);

  return (
    <div className={clsx('stdHeader-headerMenu-container', bAct && 'act')} ref={refParent}>
      <button
        onClick={() => {
          setBAct(!bAct);
          onOpen();
        }}
        type="button"
      > {iconContent}
      </button>
      {bAct && (
      <div className={clsx('stdHeader-headerMenu', !!topContent && 'stdHeader-headerMenu-withTopContent')}>
        <div className="stdHeader-headerMenu-top">
          <div>
            <div>{titre}</div>
            <ButtonClose onClick={() => setBAct(false)} />
          </div>
          <div className="stdHeader-headerMenu-topContent">
            {topContent}
          </div>

        </div>
        <div className={clsx('stdHeader-headerMenu-content', className)}>
          {children}
        </div>
      </div>
      )}
    </div>
  );
};

HeaderMenu.propTypes = {
  className: PropTypes.string,
  titre: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
  topContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  iconContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  setOnCloseCallback: PropTypes.func,
};

HeaderMenu.defaultProps = {
  className: '',
  topContent: null,
  onOpen: () => {},
  setOnCloseCallback: () => {},
};

export default HeaderMenu;
