import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import countries from 'i18n-iso-countries';
import ButtonClose from '../../components/UI/ButtonClose';
import Button from '../../components/UI/Button';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import Popup from '../../components/UI/Popup/Popup';
import Checkbox from '../../components/UI/Checkbox';
import truncate from '../../Lib/misc/truncate';
import Delegates from './Delegates';
import ComponentTitreWithClose from "../../components/UI/ComponentTitreWithClose";
import Chat from "./Chat";

const WhoIsWhoFilter = ({
  label, value, onChange, data, dataPrint,
}) => {
  const [isShowingPopup, setIsShowingPopup] = useState(false);

  const toggleValue = (newEl) => {
    if (value.includes(newEl)) {
      onChange(value.filter((el) => el !== newEl));
    } else {
      onChange([...value, newEl]);
    }
  };

  const dataSorted = data.sort((a, b) => dataPrint(a).localeCompare(dataPrint(b)));

  return (
    <>
      { isShowingPopup && (
        <Popup onClosePopup={() => setIsShowingPopup(false)} className="component-whoiswho-filter-popupSelect" variant="smallPadding">
          <h2 className="popup-titre">Select</h2>
          <div className="component-whoiswho-filter-popupSelect-list">
            {dataSorted.map((el, key) => (
              <button key={key} type="button" onClick={() => toggleValue(el)} className={clsx(value.includes(el) && 'act')}>
                <Checkbox divVersion isAct={value.includes(el)} />
                {dataPrint(el)}
              </button>
            ))}
          </div>
          <Button small wide onClick={() => setIsShowingPopup(false)}>Validate</Button>
        </Popup>
      ) }
      <button type="button" onClick={() => setIsShowingPopup(true)}>
        <div>
          {label}
          <i className="ptl-carret" />
        </div>
        <div>{value.length ? truncate(value.join(' | '), 30) : <>&nbsp;</>}</div>
      </button>
    </>
  );
};

WhoIsWhoFilter.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  dataPrint: PropTypes.func,
};

WhoIsWhoFilter.defaultProps = {
  dataPrint: (data) => data,
};

const VIEW_DELEGATES = 'VIEW_DELEGATES';
const VIEW_FILTER = 'VIEW_FILTER';

const WhoIsWho = ({ onClose, withBackground }) => {
  const [view, setView] = useState(VIEW_DELEGATES);
  const [interest, setInterest] = useState([]);
  const [companyField, setCompanyField] = useState([]);
  const [country, setCountry] = useState([]);
  const [search, setSearch] = useState('');
  const chatIframe = useRef();
  const { iframeToken } = useSelector((state) => state.stateDataReducer.loggedUtilisateur);

  const url = `${process.env.DATARIUM_CHAT_URL}/my-chat?mytoken=${iframeToken}`;
  const [delegatesData] = useRestClient('utilisateurs/delegates-data');

  if (!delegatesData) {
    return <Chargement height50 />;
  }

  const openChat = (toToken) => {
    if (chatIframe.current) {
      chatIframe.current.contentWindow.postMessage({
        event: 'openChat',
        toToken,
      }, '*');
    }
  };

  const reset = () => {
    setCompanyField([]);
    setInterest([]);
    setCountry([]);
    setSearch('');
  };

  const resizeIframe = () => {
    if (chatIframe.current) {
      // chatIframe.current.style.height = `${chatIframe.current.contentWindow.document.documentElement.scrollHeight}px`;
    }
  };
  return (
    <div className={clsx('component-whoiswho', withBackground && 'component-whoiswho-withBackground')}>

      <ComponentTitreWithClose onClick={onClose}>Networking</ComponentTitreWithClose>

      <div>
        <div className="component-whoiswho-left">
          {/*<h2 className="stdTitle stdTitle-withMarginBot">List of delegates</h2>*/}

          <div className="component-whoiswho-left-top">
            <button
              type="button"
              className={clsx(view === VIEW_DELEGATES && 'act')}
              onClick={() => {
                setView(VIEW_DELEGATES);
                reset();
              }}
            >All delegates
            </button>

            <button type="button" className={clsx(view === VIEW_FILTER && 'act')} onClick={() => (view === VIEW_FILTER ? setView(VIEW_DELEGATES) : setView(VIEW_FILTER))}>
              {/*<i className="ptl-filtre" />*/}
              Filter
            </button>

            {/* <InputWithIcon icon="loupe" value={search} onChange={setSearch} variant="transparent" placeholder="SEARCH BY NAME" /> */}
          </div>

          { ((() => {
            switch (view) {
              default:
              case VIEW_DELEGATES: {
                return (
                  <Delegates
                    onChatButtonClicked={openChat}
                    search={search.length > 1 ? search : ''}
                    filter={{
                      country,
                      interest,
                      companyField,
                    }}
                  />
                );
              }
              case VIEW_FILTER: {
                return (
                  <div className="component-whoiswho-filter">

                    <div className="component-whoiswho-filter-middle">
                      { delegatesData.companyField && <WhoIsWhoFilter data={delegatesData.companyField} label="Sector of Activity" onChange={setCompanyField} value={companyField} /> }
                      {/* <WhoIsWhoFilter data={delegatesData.interest} label="Interest" onChange={setInterest} value={interest} /> */}
                      <WhoIsWhoFilter data={delegatesData.country} label="Country" onChange={setCountry} value={country} dataPrint={(country) => countries.getName(country, 'en') || country} />
                      {/* <WhoIsWhoFilter data={delegatesData.topic} label="Topic" onChange={setTopic} value={topic} /> */}
                    </div>

                    <div className="component-whoiswho-filter-bottom">
                      <Button variant="transparentFade lowercase" small onClick={reset}>Reset</Button>
                      <Button variant="transparent lowercase" small onClick={() => setView(VIEW_DELEGATES)}>Apply</Button>
                    </div>
                  </div>
                );
              }
            }
          })())}
        </div>

        <div className="component-whoiswho-right">
          <h2 className="">My chats</h2>

            {/*<Chat />*/}
          <iframe src={url} ref={chatIframe} allowTransparency onLoad={resizeIframe} />
        </div>
      </div>
    </div>
  );
};

WhoIsWho.propTypes = {
  onClose: PropTypes.func,
  withBackground: PropTypes.bool,
};

WhoIsWho.defaultProps = {
  onClose: null,
  withBackground: false,
};

export default WhoIsWho;
