import React, { useState } from 'react';
import clsx from 'clsx';
import ProgramSpeaker from './ProgramSpeaker';
import SpeakerDesc from '../../components/UI/SpeakerDesc';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import Popup from '../../components/UI/Popup/Popup';
import {useTranslation} from "react-i18next";
import plusButton from '../../../img/GHS/plusButton.svg';
import minusButton from '../../../img/GHS/minusButton.svg';

const ProgramSpeakers = ({ }) => {
  const {t} = useTranslation();
  const [utilisateurs] = useRestClient('speakers');
  const [arIdsExpanded, setArIdsExpanded] = useState([]);
  const [selectedUtilisateurId, setSelectedUtilisateurId] = useState(null);


  if (!utilisateurs) {
    return <Chargement height50 />;
  }

  return (
    <div className={clsx('component-programSpeakers')}>


      { selectedUtilisateurId && <Popup variant="noPadding" onClosePopup={() => setSelectedUtilisateurId(null)}><ProgramSpeaker utilisateurId={selectedUtilisateurId} onBack={() => setSelectedUtilisateurId(null)} forSpeakers /></Popup>}
      {/* <Carousel> */}
      {[...utilisateurs].filter(({ bCacherSpeaker }) => !bCacherSpeaker).map((utilisateur) => {

        const expanded = arIdsExpanded.includes(utilisateur.id);

        const sessions = [...utilisateur.programmeSpeakers, ...utilisateur.programmeModerators];

        return (
          <div key={utilisateur.id} className={clsx(expanded && "expanded")}>
            <button type="button" onClick={() => {
              if (arIdsExpanded.includes(utilisateur.id)) {
                setArIdsExpanded(arIdsExpanded.filter((arId) => arId !== utilisateur.id));
              }
              else {
                setArIdsExpanded([...arIdsExpanded, utilisateur.id]);
              }
            }
            }><img src={expanded ? minusButton : plusButton} /></button>

            <div className="component-programSpeakers-speakerEl">
              <SpeakerDesc utilisateur={utilisateur}/>
            </div>

            {(!!utilisateur.description && utilisateur.description != 'NULL') ? <p className="component-programSpeakers-description wysiwyg" dangerouslySetInnerHTML={{__html: utilisateur.description}}/> : <br/>}

            {sessions.length ? (
              <div className="component-programSpeakers-sessions">
                <h2>{t('program.speakers.Session(s)')}:</h2>
                {sessions.map(({  id, programme }, key) => (
                  <div key={key}>
                    <div>{programme.realDateDebut.toFormat('HH:mm')} - {programme.realDateFin.toFormat('HH:mm')}</div>
                    <h3>{programme.name}</h3>
                  </div>
                ))}
              </div>
            ) : <div className="component-programSpeakers-sessions">{t('program.speakers.No session upcoming')}</div>}

          </div>
        );
      })}
      {/* </Carousel> */}


    </div>
  );
};

export default ProgramSpeakers;
