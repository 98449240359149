import React from 'react';
import PropTypes from 'prop-types';
import { GlobalHotKeys } from 'react-hotkeys';
import useInterface from '../Lib/useInterface';

const AppHotkeys = ({ children }) => {
  const { switchTesting } = useInterface();
  return (
    <GlobalHotKeys
      keyMap={{
        TEST_SITE: 'alt+shift+n',
      }}

      handlers={{
        TEST_SITE: () => {
          switchTesting();
        },

      }}
    >
      {children}
    </GlobalHotKeys>
  );
};

AppHotkeys.propTypes = { children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]) };

AppHotkeys.defaultProps = { children: null };

export default AppHotkeys;
