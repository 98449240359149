import React from 'react';
import { useTranslation } from 'react-i18next';
import changeLanguage from '../../Lib/misc/changeLanguage';
import useInterface from '../../Lib/useInterface';
import i18n from '../../i18n';

const LanguageButton = ({}) => {
  const { i18n } = useTranslation();
  const { changeInterfaceLanguage } = useInterface();

  const setLang = (lang) => {
    document.body.classList.add('fade');
    window.setTimeout(() => {
      document.documentElement.setAttribute('lang', lang);
      changeInterfaceLanguage(lang);
      changeLanguage(lang, true);
      window.setTimeout(() => {
        document.body.classList.remove('fade');
      }, 50);
    }, 300);
  };

  return (
    <>{i18n.language === 'en' ? (
      <button
        type="button"
        onClick={() => {
          setLang('ar');
        }}
      >عربي
      </button>
    ) : (
      
      <button
        type="button"
        onClick={() => {
          setLang('en');
        }}
      >ENG
      </button>
    )}
    </>
  );
};

export default LanguageButton;
