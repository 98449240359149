import React, { useMemo, useState } from 'react';
import countryList from 'react-select-country-list';
import Fieldset from './Fieldset';
import Select from './Select/Select';

const FieldsetSelectCountry = ({ value, ...props }) => {
  const options = useMemo(() => countryList().getData(), []);

  return (
    <Fieldset
      {...props}
      input={(othersProps) => (
        <div className="stdSelectCountry">
          { value ? <img src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`} /> : <div />}
          <Select value={value} options={options} {...othersProps} {...props} />
        </div>
      )}
    />
  );
};

export default FieldsetSelectCountry;
