import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Vimeo from '@u-wave/react-vimeo';
import Video from '../../components/UI/Video';
import Button from '../../components/UI/Button';
import LibraryVideo from './LibraryVideo';
import VideoPopup from '../../components/UI/VideoPopup';

const videoData = [
  {
    plenary: [
      { title: 'Future Minerals Forum 2023 - Opening Ceremony (EN)', link: 'https://vimeo.com/788534852' },
      { title: 'Future Minerals Forum 2023 - Opening Ceremony (AR)', link: 'https://vimeo.com/788618806' },
      { title: 'Future Minerals Forum 2023 - Opening Show', link: 'https://vimeo.com/788542622' },
      { title: 'Supply chain resilience: The region’s contribution to diversifying mineral supply (EN)', link: 'https://vimeo.com/788541689' },
      { title: 'Supply chain resilience: The region’s contribution to diversifying mineral supply (AR)', link: 'https://vimeo.com/788626617' },
      { title: 'The role of government in making the region an investment destination (EN)', link: 'https://vimeo.com/788599686' },
      { title: 'The role of government in making the region an investment destination (AR)', link: 'https://vimeo.com/788617705' },
      { title: 'Mining and owning the energy transition: Mineral potential, becoming ESG and climate smart (EN)', link: 'https://vimeo.com/788541242' },
      { title: 'Mining and owning the energy transition: Mineral potential, becoming ESG and climate smart (AR)', link: 'https://vimeo.com/788628531' },
      { title: 'Thinking outside the box: Can mining overcome the challenges (EN)', link: 'https://vimeo.com/788540479' },
      { title: 'Thinking outside the box: Can mining overcome the challenges (AR)', link: 'https://vimeo.com/788629609' },
      { title: 'Empowering critical mineral supply chains in the region: How G20 countries can support (EN)', link: 'https://vimeo.com/788540052' },
      { title: 'Empowering critical mineral supply chains in the region: How G20 countries can support (AR)', link: 'https://vimeo.com/788631270' },
      { title: 'HRH Prince Abdulaziz bin Salman Al Saud Key note (EN)', link: 'https://vimeo.com/788540932' },
      { title: 'HRH Prince Abdulaziz bin Salman Al Saud Key note (AR)', link: 'https://vimeo.com/788632666' },
      { title: 'Linking the region with the world: Creating spaces for collaboration on mineral development (EN)', link: 'https://vimeo.com/788539845' },
      { title: 'Linking the region with the world: Creating spaces for collaboration on mineral development (AR)', link: 'https://vimeo.com/788633293' },
      { title: 'Turning intent into action: Creating a mineral development roadmap for the region (EN)', link: 'https://vimeo.com/788539021' },
      { title: 'Turning intent into action: Creating a mineral development roadmap for the region (AR)', link: 'https://vimeo.com/788633865' },
      { title: 'Reimagining Mining (EN)', link: 'https://vimeo.com/788538764' },
      { title: 'Reimagining Mining (AR)', link: 'https://vimeo.com/788635709' },
      { title: 'Mitchell Hooke key note (EN)', link: 'https://vimeo.com/788538674' },
      { title: 'Mitchell Hooke key note (AR)', link: 'https://vimeo.com/788636316' },
      { title: 'The mineral company of the future (EN)', link: 'https://vimeo.com/788538049' },
      { title: 'The mineral company of the future (AR)', link: 'https://vimeo.com/788636543' },
      { title: 'Beyond perceptions of the region (EN)', link: 'https://vimeo.com/788537429' },
      { title: 'Beyond perceptions of the region (AR)', link: 'https://vimeo.com/788638112' },
      { title: 'Next generation exploration and mining: Acceleration and technology for the future (EN)', link: 'https://vimeo.com/788537156' },
      { title: 'Next generation exploration and mining: Acceleration and technology for the future (AR)', link: 'https://vimeo.com/788639548' },
      { title: 'Financing exploration, mining, and the energy transition (EN)', link: 'https://vimeo.com/788536514' },
      { title: 'Financing exploration, mining, and the energy transition (AR)', link: 'https://vimeo.com/788621471' },
      { title: 'What should ESG in the minerals space look like over the next 5-10 years? (EN)', link: 'https://vimeo.com/788536084' },
      { title: 'What should ESG in the minerals space look like over the next 5-10 years? (AR)', link: 'https://vimeo.com/788620721' },
      { title: 'Juniors: Attracting and maintaining a vibrant exploration sector (EN)', link: 'https://vimeo.com/788535369' },
      { title: 'Juniors: Attracting and maintaining a vibrant exploration sector (AR)', link: 'https://vimeo.com/788619572' },
      { title: 'Call for Action - Summaries and calls to action (EN)', link: 'https://vimeo.com/788535204' },
      { title: 'Call for Action - Summaries and calls to action (AR)', link: 'https://vimeo.com/788619374' },
    ],
  },
  {
    plenary: [
      { title: 'Welcome Address (EN)', link: 'https://vimeo.com/788940499' },
      { title: 'Welcome Address (AR)', link: 'https://vimeo.com/788947210' },
      { title: 'How does mining become a development partner to catalyse and accelerate prosperity (EN)', link: 'https://vimeo.com/788940302' },
      { title: 'How does mining become a development partner to catalyse and accelerate prosperity (AR)', link: 'https://vimeo.com/788947036' },
      { title: 'Can demand for critical minerals that power the green energy transition be met sustainably? (EN)', link: 'https://vimeo.com/788940230' },
      { title: 'Can demand for critical minerals that power the green energy transition be met sustainably? (AR)', link: 'https://vimeo.com/788946973' },
      { title: 'Saudi Arabia: Developing and growing investment in minerals value chains (EN)', link: 'https://vimeo.com/788940091' },
      { title: 'Saudi Arabia: Developing and growing investment in minerals value chains (AR)', link: 'https://vimeo.com/788946817' },
      { title: 'Looking beyond the horizon: New energy metals (EN)', link: 'https://vimeo.com/788940006' },
      { title: 'Looking beyond the horizon: New energy metals (AR)', link: 'https://vimeo.com/788946739' },
      { title: 'The region as a hydrogen and green metals powerhouse (EN)', link: 'https://vimeo.com/788939871' },
      { title: 'The region as a hydrogen and green metals powerhouse (AR)', link: 'https://vimeo.com/788946588' },
      { title: 'Hydrogen and the value chain (EN)', link: 'https://vimeo.com/788939656' },
      { title: 'Hydrogen and the value chain (AR)', link: 'https://vimeo.com/788946379' },
      { title: 'Fireside Chat: Peter Bryant & Suliman Al Mazroua (EN)', link: 'https://vimeo.com/788939592' },
      { title: 'Fireside Chat: Peter Bryant & Suliman Al Mazroua (AR)', link: 'https://vimeo.com/788946306' },
      { title: 'Creating resilient minerals supply chains (EN)', link: 'https://vimeo.com/788939389' },
      { title: 'Creating resilient minerals supply chains (AR)', link: 'https://vimeo.com/788946046' },
      { title: 'Incrementalism vs Transformation: The era of incremental change is over (EN)', link: 'https://vimeo.com/788939173' },
      { title: 'Incrementalism vs Transformation: The era of incremental change is over (AR)', link: 'https://vimeo.com/788945853' },
      { title: 'Diversity, equity, and inclusion (EN)', link: 'https://vimeo.com/788939001' },
      { title: 'Diversity, equity, and inclusion (AR)', link: 'https://vimeo.com/788945686' },
      { title: 'The value of information: the role of geophysical data acquisition (EN)', link: 'https://vimeo.com/788938928' },
      { title: 'The value of information: the role of geophysical data acquisition (AR)', link: 'https://vimeo.com/788945629' },
      { title: 'Building out the battery supply chain (EN)', link: 'https://vimeo.com/788938763' },
      { title: 'Building out the battery supply chain (AR)', link: 'https://vimeo.com/788945480' },
      { title: 'The downstream value chains (EN)', link: 'https://vimeo.com/788938556' },
      { title: 'The downstream value chains (AR)', link: 'https://vimeo.com/788945314' },
      { title: 'How is the risk appetite in the value chain changing? (EN)', link: 'https://vimeo.com/788938360' },
      { title: 'How is the risk appetite in the value chain changing? (AR)', link: 'https://vimeo.com/788945153' },
      { title: 'Supply chain innovation in a decarbonizing environment (EN)', link: 'https://vimeo.com/788938178' },
      { title: 'Supply chain innovation in a decarbonizing environment (AR)', link: 'https://vimeo.com/788944967' },
      { title: 'Call for Action - Summaries and calls to action (EN)', link: 'https://vimeo.com/788938125' },
      { title: 'Call for Action - Summaries and calls to action (AR)', link: 'https://vimeo.com/788944916' },
      { title: 'Spotlight on Imagining the Future (EN)', link: 'https://vimeo.com/788943665' },
      { title: 'Spotlight on Imagining the Future (AR)', link: 'https://vimeo.com/788944705' },
      { title: 'Chair’s closing remarks (EN)', link: 'https://vimeo.com/788944051' },
      { title: 'Chair’s closing remarks (AR)', link: 'https://vimeo.com/788944634' },
      { title: 'Sponsor and Exhibitor Recognition Awards (EN)', link: 'https://vimeo.com/788943845' },
      { title: 'Sponsor and Exhibitor Recognition Awards (AR)', link: 'https://vimeo.com/788944414' },
    ],
  },
];

const LibraryReplays = ({ }) => {
  const { t } = useTranslation();
  const [day, setDay] = useState(0);
  const [room, setRoom] = useState('plenary');

  const shouldDisplay = true;

  if (!shouldDisplay) {
    return (
      <div className="component-library-replays-before">
        <h2>COMING SOON</h2>

        <h3>FMF 2023 videos will be added post-event. <br />
          Stay tuned for more!
        </h3>
      </div>
    );
  }

  return (
    <div className="component-library-replays">
      <nav>
        <button className={clsx(day === 0 && 'act')} type="button" onClick={() => setDay(0)}>Day 01 | Wednesday, November 29</button>
        <button className={clsx(day === 1 && 'act')} type="button" onClick={() => setDay(1)}>Day 02 | Thursday, November 30</button>
      </nav>
      {/*<nav>*/}
      {/*  <button className={clsx(room === 'plenary' && 'act')} type="button" onClick={() => setRoom('plenary')}>plenary</button>*/}
      {/*  <button className={clsx(room === 'exhibition' && 'act')} type="button" onClick={() => setRoom('exhibition')}>exhibition</button>*/}
      {/*  <button className={clsx(room === 'gcc' && 'act')} type="button" onClick={() => setRoom('gcc')}>gcc</button>*/}
      {/*  <button className={clsx(room === 'marketplace' && 'act')} type="button" onClick={() => setRoom('marketplace')}>marketplace</button>*/}
      {/*</nav>*/}

      <div className="component-library-replays-content">
        { videoData[day][room].map(({ title, link }, key) => (
          <div key={`${day}-${room}-${key}`}><Vimeo video={link} />
            <div className="component-library-replays-content-titre">{title}</div>
          </div>
        ))}

      </div>

    </div>
  );
};

LibraryReplays.propTypes = {
};

LibraryReplays.defaultProps = {
};

export default LibraryReplays;
