import React, { useState } from 'react';
import { DateTime } from 'luxon';
import axios from 'axios';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import useInterface from '../../../../Lib/useInterface';
import useRestClient from '../../../../Lib/useRestClient';
import Chargement from '../../../../components/UI/Chargement';
import StaffContenu from '../../StaffContenu';
import ButtonAjouter from '../../../../components/UI/ButtonAjouter';
import truncate from '../../../../Lib/misc/truncate';
import Fieldset from '../../../../components/UI/Form/Fieldset';
import Button from '../../../../components/UI/Button';
import Switch from '../../../../components/UI/Form/Switch';
import SplitDiv from '../../../../components/UI/Div/SplitDiv';
import FieldsetTextarea from '../../../../components/UI/Form/FieldsetTextarea';
import FieldsetDatetime from '../../../../components/UI/Form/FieldsetDatetime';
import FieldsetFile from '../../../../components/UI/Form/FieldsetFile';

const UpcomingEventEdit = ({ upcomingEvent, onCancel, onSuccess }) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [uploadingData, setUploadingData] = useState({
    image: null,
  });

  return (
    <Formik
      initialValues={upcomingEvent || {
        name: '',
        location: '',
        isStarted: false,
        date: DateTime.local(),
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const formData = new FormData();
        for (const key in values) {
          formData.set(key, values[key]);
        }
        for (const key in uploadingData) {
          if (uploadingData[key]) {
            formData.set(key, uploadingData[key]);
          }
        }

        try {
          if (upcomingEvent && upcomingEvent.id) {
            formData.append('_method', 'PATCH');
            await axios.post(`/staff/upcoming-events/${upcomingEvent.id}`, formData, { headers: { 'Content-Type': 'application/json' } });
          } else {
            await axios.post('/staff/upcoming-events', formData, { headers: { 'Content-Type': 'application/json' } });
          }

          sendMessage('The upcoming event has been saved');
          onSuccess();
        } catch (err) {
          sendRestErrorMessage(err);
        }
        setSubmitting(false);
      }}

      validationSchema={Yup.object().shape({
        name: Yup.string().required('You must provide this field'),
        location: Yup.string().required('You must provide this field'),
        date: Yup.string().required('You must provide this field'),
      })}
    >
      {({ values, isSubmitting }) => (
        <Form className="stdForm form-100">

          <FieldsetFile withPhoto={upcomingEvent && !!upcomingEvent.image} name="image" value={upcomingEvent && upcomingEvent.image} onUpload={(image) => setUploadingData({ ...uploadingData, image })} extensions={['jpg', 'png']}>Upload image</FieldsetFile>

          <SplitDiv>
            <Fieldset placeholder="Name" name="name">Name</Fieldset>
            <Fieldset placeholder="Location" name="location">Location</Fieldset>
          </SplitDiv>

          <SplitDiv>
            <FieldsetDatetime placeholder="mm/dd/YYYY HH:mm" name="date">Date</FieldsetDatetime>
            <Fieldset name="isStarted" input={(otherProps) => <Switch value={values.isStarted} {...otherProps} />}>Is enabled </Fieldset>
          </SplitDiv>

          <div className="rightAlign stdForm-submitLine">
            <Button variant="transparent" onClick={onCancel}>Cancel</Button>
            <Button type="submit" disabled={isSubmitting}>Save changes</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

UpcomingEventEdit.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  upcomingEvent: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};
UpcomingEventEdit.defaultProps = {
  upcomingEvent: null,
};
const UpcomingEvents = () => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [deletingUpcomingEvent, setDeletingUpcomingEvent] = useState(null);
  const [editingUpcomingEvent, setEditingUpcomingEvent] = useState(null);
  const [adding, setAdding] = useState(false);
  const [upcomingEvents] = useRestClient('/upcoming-events', null, [lastAction]);

  const doDeleteUpcomingEvents = async (upcomingEvent) => {
    try {
      await axios.delete(`/staff/upcoming-events/${upcomingEvent.id}`);
      setDeletingUpcomingEvent(null);
      setLastAction(DateTime.local());
      sendMessage('The partner has been deleted');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  if (!upcomingEvents) {
    return <Chargement />;
  }

  if (editingUpcomingEvent || adding) {
    return (
      <StaffContenu titre="Upcoming events"><UpcomingEventEdit
        upcomingEvent={adding ? null : editingUpcomingEvent}
        onCancel={() => {
          setEditingUpcomingEvent(null);
          setAdding(false);
        }}
        onSuccess={() => {
          setEditingUpcomingEvent(null);
          setAdding(false);
          setLastAction(DateTime.local());
        }}
      />
      </StaffContenu>
    );
  }

  return (
    <StaffContenu titre="Upcoming events" rightContent={!!upcomingEvents.length && <ButtonAjouter className="floatRight" onClick={() => setAdding(true)}>Add more</ButtonAjouter>}>

      {upcomingEvents.length ? (
        <table className="table-100 stdTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Location</th>
              <th>Is started</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {upcomingEvents.map((upcomingEvent) => (
              <tr key={upcomingEvent.id}>
                <td>{truncate(upcomingEvent.name, 40)}</td>
                <td>{truncate(upcomingEvent.location, 40)}</td>
                <td>{upcomingEvent.isStarted ? 'Yes' : 'No'}</td>

                {deletingUpcomingEvent === upcomingEvent.id ? (
                  <td>
                    <span>Are you sure ?</span>
                    <div className="stdSplitActions stdSplitActions-confirm">
                      <button onClick={() => setDeletingUpcomingEvent(null)}><i className="ptl-close2" /> no</button>
                      <button onClick={() => doDeleteUpcomingEvents(upcomingEvent)}><i className="ptl-valid" /> yes</button>
                    </div>
                  </td>
                ) : (
                  <td className="stdSplitActions">
                    <button type="button" onClick={() => setEditingUpcomingEvent(upcomingEvent)}>edit</button>
                    <button type="button" onClick={() => setDeletingUpcomingEvent(upcomingEvent.id)}>delete</button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="center">
          <div className="mid midMargin-b">No video has been uploaded</div>
          <ButtonAjouter onClick={() => setAdding(true)}>Add a video</ButtonAjouter>
        </div>
      )}

    </StaffContenu>
  );
};
export default UpcomingEvents;
