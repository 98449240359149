import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import logo from '../../../img/GHS/logo.svg';
import useSecurity from '../../Lib/useSecurity';
import LoginPopup from './LoginPopup';
import EditProfile from './EditProfile';
import EvenementState from '../../Lib/EvenementState';
import ProfileMenu from './Menus/ProfileMenu';
import { NotificationsMenu } from './Menus/NotificationsMenuButton';
import notifications from '../../../img/GHS/notifications.svg';
import logoutImg from '../../../img/GHS/disconnect.svg';
import avatarEmpty from '../../../img/MISK22/profil2.svg';

const PAGE_LIVE = 'live';
const PAGE_LOBBY = 'lobby';
const PAGE_NETWORKING = 'networking';
const PAGE_CONCLAVE = 'conclave';
const PAGE_INTERVIEW = 'interview';

const Header = ({ beforeLogout }) => {
  const iframeRef = useRef();
  const { i18n } = useTranslation();
  const [willLogout, setWillLogout] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [willLogin, setWillLogin] = useState(false);
  const config = useSelector((state) => state.stateDataReducer.config);
  const history = useHistory();
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);

  const logout = async () => {
    if (beforeLogout) {
      await beforeLogout();
    }
    if (parseInt(process.env.FEATURE_NOTIFICATIONS) === 1) {
      setWillLogout(true);
    } else {
      await axios.get('logout');
      window.location.href = '/chargement';
    }
  };

  const params = window.location.search.substr(1);
  const isStarted = config && parseInt(config.isFinished) !== EvenementState.UNSTARTED;
  const shouldDisplayLogin = isStarted || (params.includes('code=testFMS!!!'));
  // const shouldDisplayLogin = true;

  const {
    isGuest, isMedia, isVIP, isConcierge, isSpeaker, isStaff,
  } = useSecurity();
  return (
    <>
      {editProfile && <EditProfile onClosePopup={() => setEditProfile(false)} />}
      <header className={clsx('stdHeader', isStaff() && 'stdHeader-forStaff')}>
        {/* <div className="stdHeader-background"><img src={topRayures} /></div> */}

        {/* <button className="stdHeader-left stdHeader-logo" type="button" onClick={() => methodDoesNotExist()}> */}
        <button className="stdHeader-left stdHeader-logo" type="button" onClick={() => history.push('/')}>
          <img src={logo} />
        </button>

        {/* <nav className="stdHeader-center"> */}
        {/*  <button onClick={() => changePage(PAGE_LIVE)} className={clsx(currentPage === 'live' && 'active', 'stdHeader-center-lobbyButton')} type="button"><div>Live</div></button> */}
        {/*  <button onClick={() => changePage(PAGE_LOBBY)} className={clsx(currentPage === 'lobby' && 'active')} type="button"><div>Lobby</div></button> */}
        {/*  { (!isSpeaker() && !isMedia()) && <button onClick={() => changePage(PAGE_NETWORKING)} className={clsx(currentPage === 'networking' && 'active')} type="button"><div>Networking</div></button> } */}
        {/*  { (!!comingInterviewNb.nbConclaves || isConcierge()) && <button onClick={() => changePage(PAGE_CONCLAVE)} className={clsx(currentPage === 'conclave' && 'active')} type="button"><div>Conclave { !!comingInterviewNb.nbConclaves && <div className="stdHeader-center-nb">{comingInterviewNb.nbConclaves}</div>}</div></button> } */}
        {/*  { !!comingInterviewNb.nbInterviews && <button onClick={() => changePage(PAGE_INTERVIEW)} className={clsx(currentPage === 'interview' && 'active')} type="button"><div>Interview { !!comingInterviewNb.nbInterviews && <div className="stdHeader-center-nb">{comingInterviewNb.nbInterviews}</div>}</div></button> } */}

        {/* </nav> */}

        <div className="stdHeader-right">
          {loggedUtilisateur ? (
            <>

              {/* <LanguageButton /> */}

              {parseInt(process.env.FEATURE_NOTIFICATIONS) === 1 && (
                <>
                  {willLogout && loggedUtilisateur && (
                    <iframe
                      src={`${process.env.DATARIUM_CHAT_URL}/logout?token=${loggedUtilisateur.iframeToken}`}
                      frameBorder="0"
                      ref={iframeRef}
                      style={{ display: 'none' }}
                      enablejsapi="1"
                      onLoad={async () => {
                        await axios.get('logout');
                        window.location.href = '/';
                      }}
                    />
                  )}
                </>
              )}

              <NotificationsMenu><img src={notifications} /></NotificationsMenu>
              {/* <ShareMenu><img src={partager} /></ShareMenu> */}
              <button type="button" onClick={() => logout()}><img src={logoutImg} /></button>
              {/*<ProfileMenu onLogout={logout}><img className="stdHeader-avatar" src={loggedUtilisateur.avatar ? `${process.env.UPLOADS_URL}${loggedUtilisateur.avatar}` : avatarEmpty} /></ProfileMenu>*/}

            </>
          ) : (
            <div className="stdHeader-right-unlogged">
              {shouldDisplayLogin ? <button type="button" className="stdBtn" onClick={() => setWillLogin(true)}>Login</button> : (
                <p>Welcome to the <b>Global Healthspan Summit</b></p>
              )}
            </div>
          )}
        </div>
      </header>
      {willLogin && <LoginPopup onClose={() => setWillLogin(false)} />}

    </>
  );
};

export default Header;

export {
  PAGE_CONCLAVE, PAGE_LIVE, PAGE_LOBBY, PAGE_NETWORKING, PAGE_INTERVIEW,
};
