import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import startPlayer, { stopPlayer, switchLanguage } from '../../Lib/misc/startNanoPlayer';

const LiveNanoPlayer = ({ channelIndex, allChannels }) => {
  const container = useRef();

  const currentChannel = allChannels[channelIndex];
  const languages = allChannels[channelIndex].langs;

  // console.log(languages);
  useEffect(() => {
    if (currentChannel) {
      startPlayer('playerDiv', 0, currentChannel.channels);
    }
    return () => {
      stopPlayer();
    };
  }, [channelIndex]);

  return (
    <div className={clsx('component-live')}>
      <div id="playerDiv" ref={container} />
      { languages.length > 1 && (
      <div id="languageButtons" className="stdMenuLang">
        { languages.map((lang, key) => <button id="btnEN" key={lang} onClick={() => switchLanguage(key, currentChannel.channels)}>{lang}</button>)}
      </div>
      )}

    </div>
  );
};

LiveNanoPlayer.propTypes = {
  channelIndex: PropTypes.number.isRequired,
  allChannels: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    langs: PropTypes.array.isRequired,
    channels: PropTypes.array.isRequired,
  })).isRequired,
};
export default LiveNanoPlayer;
