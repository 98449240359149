import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Chargement from '../../components/UI/Chargement';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import Popup from '../../components/UI/Popup/Popup';
import * as StateAction from '../../Redux/actions/State';

const ShouldBeLogged = ({ children, ifNotLogged }) => {
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const history = useHistory();

  if (!loggedUtilisateur) {
    return (
      <LoginPopup
        onClose={() => {
          if (!ifNotLogged) {
            return history.push('/');
          }
          ifNotLogged();
        }}
        onCloseSuccess={() => {}}
      />
    );
  }

  return children;
};

const LoginPopup = ({ onClose, onCloseSuccess }) => {
  const recaptchaRef = useRef();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const onChangeCapcha = (val) => {
  };
  const tryConnect = async (password, captcha, setSubmitting = null) => {
    try {
      const resp = await axios.post('login_check', {
        username: password,
        password,
        captcha,
      });

      const resp2 = await axios.get('state');
      dispatch({ type: StateAction.LOGIN, state: resp2.data });

      if (onCloseSuccess) {
        onCloseSuccess();
      } else {
        onClose();
      }
    } catch (err) {
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (err.response.data.code === 444) {
        setError('Your ip is not whitelisted');
      } else {
        setError('Please verify your password');
      }

      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
  };

  return (
    <Popup onClosePopup={onClose} className="stdLoginPopup">

      <div className="stdLoginPopup-titre">Login</div>

      <Formik
        initialValues={{
          password: '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const captchaVal = await recaptchaRef.current.executeAsync();
            setError(null);
            await tryConnect(values.password, captchaVal);
            setSubmitting(false);
          } catch (err) {
            setSubmitting(false);
            // console.log('captcha err', err);
          }
        }}

        validationSchema={Yup.object().shape({
          password: Yup.string().required('Please provide your password'),
        })}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form>

            <div className="stdContainer stdContainer-30">
              <Fieldset type="password" placeholder="Type your access code" name="password" onIconClic={() => handleSubmit()}>Access code</Fieldset>
              <a href="https://registration.fashionfutures.live/forget-password" target="_blank" className="stdLoginPopup-forgotPassword">Forgot your access code?</a>
              <div className="component-connexion-captcha">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6Ldcq9EZAAAAAI7TW8UyizDAMUnD1L230q4S5OBz"
                  onChange={onChangeCapcha}
                />
              </div>
              <Button type="submit" wide>Login</Button>

              <div className="bold center tinyMargin-t">Don’t have an account? <a href="https://registration.fashionfutures.com/virtual/request-invitation" target="_blank" rel="noopener" className="main">Sign Up</a></div>
            </div>
            {isSubmitting && <Chargement withoutText />}
            {error && <div className="error smallMargin-t center">{error}</div>}

          </Form>
        )}
      </Formik>
    </Popup>
  );
};

LoginPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCloseSuccess: PropTypes.func,
};

LoginPopup.defaultProps = {
  onCloseSuccess: null,
};

export { ShouldBeLogged };
export default LoginPopup;
