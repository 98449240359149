import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { convertNodeToElement } from 'react-html-parser';
import countries from 'i18n-iso-countries';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import Popup from '../../components/UI/Popup/Popup';
import * as StateAction from '../../Redux/actions/State';
import useInterface from '../../Lib/useInterface';
import toFixedOrRound from '../../Lib/misc/toFixedOrRound';
import chat from '../../../img/FAFU/chat.svg';
import card from '../../../img/GAS/vCard.svg';
import truncate from '../../Lib/misc/truncate';
import Pagination from '../../components/UI/Pagination';
import AvatarForUtilisateur from "../../components/UI/AvatarForUtilisateur.jsx";

const Delegate = ({
  onChatButtonClicked, utilisateur, matching, exchangeToken, openBio, index,
}) => {
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);

  const hasExchangedToken = loggedUtilisateur.tokenExchanged && loggedUtilisateur.tokenExchanged.includes(utilisateur.token);
  return (
    <>
      <div className="stdDelegate">
        {/* <div>{index < 10 ? `0${index}` : index}</div> */}
        {/* <div><AvatarForUtilisateur utilisateur={utilisateur} /></div> */}
        <div>
          <div>{`${truncate(`${utilisateur.lastname} ${utilisateur.firstname}`, 25)}`} </div>
          <div>
            <div>
              <div>{utilisateur.position}</div>
              {/* <div>{utilisateur.company}</div> */}
              <div>{countries.getName(utilisateur.country, 'en')}</div>
              <div>{toFixedOrRound(100 * matching, 0)}%</div>
              {/* <div> <Button variant="tinyOrange" onClick={openBio}>Open biography</Button></div> */}
            </div>
            <div>

              <button type="button" onClick={onChatButtonClicked}>
                {/*<img src={chat} />*/}
                Chat
              </button>

              { !hasExchangedToken ? (
                <button type="button" onClick={() => exchangeToken(utilisateur)} disabled={hasExchangedToken}>
                  {/*<img src={card} />*/}
                  vCard
                </button>
              ) : <div className="isExchanged">
                {/*<img src={card} />*/}
                vCard</div> }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Delegate.propTypes = {
  index: PropTypes.number.isRequired,
  openBio: PropTypes.func.isRequired,
  exchangeToken: PropTypes.func.isRequired,
  onChatButtonClicked: PropTypes.func.isRequired,
  matching: PropTypes.number.isRequired,
  utilisateur: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
  }).isRequired,
};

const Delegates = ({ onChatButtonClicked, search, filter }) => {
  const [showBioUtilisateur, setShowBioUtilisateur] = useState(null);
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const url = filter.companyField.length + filter.country.length === 0 ? `utilisateurs-matching?search=${search}&page=${page}` : `utilisateurs-matching?companyField=${filter.companyField.join(',')}&country=${filter.country.join(',')}&search=${search}&page=${page}`;

  const [utilisateursData] = useRestClient(url, null, [search, page]);
  if (!utilisateursData) {
    return <Chargement />;
  }

  const exchangeToken = async (utilisateur) => {
    try {
      const resp = await axios.get(`/utilisateurs/${utilisateur.id}/exchange-token`);
      dispatch({ type: StateAction.LOGIN, state: resp.data });
      sendMessage('Business Card Request has been sent');
    } catch (err) {
      sendRestErrorMessage(err);
    }
  };
  const transform = (node, index) => {
    if (node.type === 'tag') {
      if (node.type === 'tag' && node.name === 'ins') {
        return <div>{ convertNodeToElement(node, index, transform) }</div>;
      }
      if (node.type === 'tag' && ['a', 'p', 'em', 'br'].includes(node.name)) {
        return convertNodeToElement(node, index, transform);
      }
      return null;
    }
  };

  const options = {
    decodeEntities: true,
    transform,
  };

  return (
    <div className="stdDelegate-container">
      { showBioUtilisateur && (
        <Popup onClosePopup={() => setShowBioUtilisateur(null)}>
          <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: showBioUtilisateur.biography }} />
        </Popup>
      )}

      {/*<div className="stdDelegate-container-top">*/}
      {/*  <div />*/}
      {/*  <div>Chat</div>*/}
      {/*  <div>Business card</div>*/}
      {/*</div>*/}
      { utilisateursData && utilisateursData.results && utilisateursData.results.map(({ matching, utilisateur }, index) => <Delegate index={index + 1} openBio={() => setShowBioUtilisateur(utilisateur)} onChatButtonClicked={() => onChatButtonClicked(utilisateur.token)} key={utilisateur.id} matching={matching} utilisateur={utilisateur} exchangeToken={exchangeToken} />)}

      <div className="center bigPadding-y">
        <Pagination noWrap nbPages={utilisateursData.pagesCount} changePage={setPage} actPage={page} total={utilisateursData.total} nbPerPage={50} />
      </div>

    </div>
  );
};

Delegates.propTypes = {
  onChatButtonClicked: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
};

export default Delegates;
