import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import MenuWindow from './MenuWindow';
import Plenary from '../Live/Plenary';

const TopWindow = () => {
  const { t } = useTranslation();

  return (
    <div className={clsx('stdMainSpace-topWindow')}>
      <Plenary />
      <MenuWindow />
    </div>
  );
};

export default TopWindow;
