import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import avatarEmpty from '../../../img/MISK22/profil2.svg';

const AvatarForUtilisateur = ({
  utilisateur, variant, ...props
}) => (
  <div className={clsx('stdAvatar', variant && `stdAvatar-${variant}`)}>
    { (utilisateur.avatar  && utilisateur.avatar !== "NULL") ? <img src={`${process.env.UPLOADS_URL + utilisateur.avatar}?v=${utilisateur.updated && utilisateur.updated.toMillis()}`} /> : <img src={avatarEmpty} />}

  </div>
);

AvatarForUtilisateur.propTypes = {
  utilisateur: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

AvatarForUtilisateur.defaultProps = {
  variant: null,
};

export default AvatarForUtilisateur;
