import React from 'react';
import TopWindow from './TopWindow';
import BottomWindow from './BottomWindow';
import Footer from './Footer';
import Header from '../Header/Header';

const MainSpace = ({ beforeLogout }) => (
  <div className="stdMainSpace stdBackgrounded">

    <div className="relative">
      <Header beforeLogout={beforeLogout} />
      <TopWindow />
      <BottomWindow />
    </div>
    <Footer className="stdFooter-backgrounded" />

  </div>
);

export default MainSpace;
