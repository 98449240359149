let config;
let player;



const startPlayer = (playerDiv, stream_number, streams) => {
  // console.log("startPlayer", stream_number);
  stream_number = stream_number || '0';


  // Get the matching language streams
  const stream = streams[stream_number];



  // Define the default stream to medium
  const startIndex = 1;

  const rtmpUrl = 'rtmp://bintu-play.nanocosmos.de:1935/play';
  const serverDefault = {
    websocket: 'wss://bintu-h5live.nanocosmos.de:443/h5live/stream/stream.mp4',
    hls: 'https://bintu-h5live.nanocosmos.de:443/h5live/http/playlist.m3u8',
    progressive: 'https://bintu-h5live.nanocosmos.de:443/h5live/http/stream.mp4',
  };




  config = {
    source: {
      entries: [
        {
          index: 0,
          h5live: {
            server: serverDefault,
            rtmp: {
              url: rtmpUrl,
              streamname: stream[0],
            },
          },
        },
        {
          index: 1,
          h5live: {
            server: serverDefault,
            rtmp: {
              url: rtmpUrl,
              streamname: stream[1],
            },
          },
        },
        {
          index: 2,
          h5live: {
            server: serverDefault,
            rtmp: {
              url: rtmpUrl,
              streamname: stream[2],
            },
          },
        },
        {
          index: 3,
          h5live: {
            server: serverDefault,
            rtmp: {
              url: rtmpUrl,
              streamname: stream[3],
            },
          },
        },
      ],
      options: {
        adaption: {
          rule: 'deviationOfMean2', // enable ABR (Automatic bitrate adaption)
          // none - Manual switching
          // deviationOfMean - Buffer based bitrate adaption rule
          // deviationOfMean2 - Enhanced buffer based bitrate adaption rule (added in v4.5)
        },
        switch: {},
      },
      startIndex,
    },
    playback: {
      autoplay: true,
      automute: true,
      muted: false,
      flashplayer: 'https://demo.nanocosmos.de/nanoplayer/nano.player.swf',
    },
    style: {
      controls: true, // Set to true to hide default control bar
    },
    events: {},
  };

  player = new NanoPlayer(playerDiv);
  player.setup(config).then(
    (config) => {
      // console.log('setup success');
      // console.log('config: ' + JSON.stringify(config, undefined, 4));
    },
    (error) => {
      console.error(error.message);
    },
  );
  setActiveButton(stream_number);
};

function setActiveButton(index) {
  for (let i = 0; i < document.getElementById('languageButtons').children.length; i++) {
    document.getElementById('languageButtons').children[i].classList.remove('active');
  }
  document.getElementById('languageButtons').children[index].classList.add('active');
}

const switchLanguage = (index, streams) => {
  player.destroy();
  startPlayer('playerDiv', index, streams);
};

function play() {
  player.play();
}

function pause() {
  player.pause();
}

function fullscreen() {
  player
    .requestFullscreen()
    .then(() => {
      console.log('requestFullscreen resolved');
    })
    .catch((err) => {
      // error reasons can be 'denied' or 'disabled' (e.g. in audio player mode)
      console.log(`requestFullscreen rejected: ${err.reason}`);
    });
}

function mute() {
  player.mute();
}

function unmute() {
  player.unmute();
}
function stopPlayer() {
  player.destroy();
}

export default startPlayer;
export { switchLanguage, stopPlayer };
