import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import filter from '../../../img/MISK22/filter.svg';
import arrow from '../../../img/MISK22/arrow.svg';
import HoverPortal from '../../components/UI/Portal/HoverPortal';
import Checkbox from '../../components/UI/Checkbox';
import ClicPortal from '../../components/UI/Portal/ClicPortal';
import getArCountryName from '../../Lib/misc/getArCountryName';

const countries = require('i18n-iso-countries');

const WhoIsWhoV2FilterDivElement = ({
  allValues, selectedValues, onChange, children, langKey,
}) => {
  const [act, setAct] = useState(false);

  const { t, i18n } = useTranslation();

  return (
    <>
      <button className={clsx(act && 'act')} type="button" onClick={() => setAct(!act)}>{children} <img src={arrow} /></button>
      {act && (
        <div>
          {allValues.map((element, index) => {
            let text = t((langKey ? `${langKey}.` : '') + element);
            if (langKey === 'countries') {
              if (i18n.language === 'ar') {
                text = getArCountryName(element);
              } else {
                text = countries.getName(element, 'en');
              }
            }
            const bIsInside = selectedValues.includes(element);

            return (
              <button
                type="button"
                key={index}
                onClick={() => {
                  if (bIsInside) {
                    onChange(selectedValues.filter((el) => el !== element));
                  } else {
                    onChange([...selectedValues, element]);
                  }
                }}
              >
                <Checkbox isAct={bIsInside} />
                {text}
              </button>
            );
          })}
        </div>
      )}
    </>
  );
};

WhoIsWhoV2FilterDivElement.propTypes = {
  langKey: PropTypes.string,
  allValues: PropTypes.array.isRequired,
  selectedValues: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

WhoIsWhoV2FilterDivElement.defaultProps = {
  langKey: null,
};

const WhoIsWhoV2FilterDiv = ({
  interest, setInterest, country, setCountry, occupation, setOccupation,
}) => {
  const { t } = useTranslation();
  const [delegatesData] = useRestClient('utilisateurs/delegates-data');

  if (!delegatesData) {
    return <Chargement height50 />;
  }
  return (
    <ClicPortal
      portalComponent={(
        <div className="component-whoiswhoV2-filters-menu">
          <WhoIsWhoV2FilterDivElement langKey="interests" selectedValues={interest} onChange={setInterest} allValues={delegatesData.interest}>{t('whoIsWho.Interests')}</WhoIsWhoV2FilterDivElement>
          <WhoIsWhoV2FilterDivElement langKey="occupations" selectedValues={occupation} onChange={setOccupation} allValues={delegatesData.allOccupations}>{t('whoIsWho.Occupation')}</WhoIsWhoV2FilterDivElement>
          <WhoIsWhoV2FilterDivElement langKey="countries" selectedValues={country} onChange={setCountry} allValues={delegatesData.country}>{t('whoIsWho.Country')}</WhoIsWhoV2FilterDivElement>
          {/* <WhoIsWhoV2FilterDivElement selectedValues={country} onChange={setCountry} allValues={delegatesData.country}>{t('whoIsWho.LinkedIn')}</WhoIsWhoV2FilterDivElement> */}
        </div>
      )}
    >
      <div className="component-whoiswhoV2-filters-btn">
        <img src={filter} /> {t('whoIsWho.Filters')}
        <img src={arrow} />
      </div>
    </ClicPortal>
  );
};

WhoIsWhoV2FilterDiv.propTypes = {
  interest: PropTypes.array.isRequired,
  country: PropTypes.array.isRequired,
  occupation: PropTypes.array.isRequired,
  setInterest: PropTypes.func.isRequired,
  setCountry: PropTypes.func.isRequired,
  setOccupation: PropTypes.func.isRequired,
};

WhoIsWhoV2FilterDiv.defaultProps = {};

export default WhoIsWhoV2FilterDiv;
