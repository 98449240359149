import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import Button from '../Button';
import Photo from '../Photo';

const FileInput = ({
  hasError, value, isTouched, onChange, withPhoto, doDelete, ...props
}) => {
  const [currentName, setCurrentName] = useState(null);
  const inputRef = useRef();

  const downloadPhoto = () => {
    window.open(`${process.env.UPLOADS_URL}${value}`, '_blank');
  };
  const uploadPhoto = () => inputRef.current && inputRef.current.click();

  return (
    <>
      {value && (
        <><Button
          small
          variant="transparent"
          onClick={downloadPhoto}
        >Download
        </Button> &nbsp;
        </>
      )}

      <Button small onClick={uploadPhoto}>Upload</Button>
      {currentName && <>&nbsp; {currentName}</>}

      {doDelete && <>&nbsp; <Button small variant="transparent" onClick={doDelete}>Delete</Button></>}
      {withPhoto && <button type="button" onClick={downloadPhoto}><Photo src={`${process.env.UPLOADS_URL}${value}`} className="floatRight" small /></button>}

      {createPortal(<input
        ref={inputRef}
        type="file"
        className="stdFileInputHidden"
        onChange={(e) => {
          const { files } = e.target;
          if (files[0]) {
            setCurrentName(files[0].name);
          }
          onChange(files);
        }}
      />, document.getElementById('portalContainer'))}

    </>
  );
};

FileInput.propTypes = {
  hasError: PropTypes.bool,
  isTouched: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  doDelete: PropTypes.func,
  withPhoto: PropTypes.bool,
};

FileInput.defaultProps = {
  value: null,
  doDelete: null,
  hasError: false,
  isTouched: false,
  withPhoto: false,
};
export default FileInput;
