import React, {
  useCallback,
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import getParentsNodesScrollables from '../../../Lib/misc/DOM/getParentsNodesScrollables';
import getWindowSize from '../../../Lib/misc/DOM/getWindowSize';
import getElementPosition from '../../../Lib/misc/DOM/getElementPosition';

const Portal = ({
  children, portalComponent, setParentRef, shouldInbl, takeWidthFromParent, setPortalRef, show, tabIndex, onPopup, dependencies, attachRight, ...props
}) => {
  const refPortal = useRef();
  const refParent = useRef();

  if (setPortalRef) {
    setPortalRef(refPortal);
  }
  if (setParentRef) {
    setParentRef(refParent);
  }

  const defaultOffset = 1000;
  const [position, setPosition] = useState({
    x: -defaultOffset,
    y: -defaultOffset,
  });
  const [inversed, setInversed] = useState(false);

  const getPosition = useCallback(() => {
    if (!refParent.current || !refPortal.current) {
      return null;
    }
    const parentPosition = getElementPosition(refParent.current);

    let newPosition = {
      x: attachRight ? parentPosition.left + (refParent.current.clientWidth - refPortal.current.clientWidth) : parentPosition.left,
      y: parentPosition.bottom,
    };

    if (refPortal.current) {
      if (refPortal.current.clientHeight + parentPosition.bottom > getWindowSize().height) {
        newPosition = {
          ...newPosition,
          y: parentPosition.bottom - refPortal.current.clientHeight - refParent.current.clientHeight,
        };
        setInversed(true);
      } else if (inversed) {
        setInversed(false);
      }

      if (refPortal.current.clientWidth + parentPosition.left > getWindowSize().width) {
        newPosition = { ...newPosition, x: parentPosition.right - refPortal.current.clientWidth };
      }
    }
    return newPosition;
  }, [refParent.current, refPortal.current]);

  const updatePosition = () => {
    setPosition(getPosition());
  };

  useEffect(() => {
    if (show) {
      if (refParent.current) {
        window.setTimeout(() => {
          setPosition(getPosition());
        }, 20);

        window.addEventListener('resize', updatePosition);
        getParentsNodesScrollables(refParent.current).forEach((node) => {
          node.addEventListener('scroll', updatePosition, { passive: true });
        });
      }
    } else {
      window.removeEventListener('resize', updatePosition);
      getParentsNodesScrollables(refParent.current).forEach((node) => {
        node.removeEventListener('scroll', updatePosition, { passive: true });
      });
    }
  }, [show, dependencies]);

  useLayoutEffect(() => () => {
    window.removeEventListener('resize', updatePosition);
    getParentsNodesScrollables(refParent.current).forEach((node) => {
      node.removeEventListener('scroll', updatePosition, { passive: true });
    });
  }, [refParent.current]);

  return (
    <div ref={refParent} className={clsx('portalTrigger', inversed && 'portalTrigger-inversed', shouldInbl && 'portalTrigger-inbl')} {...props}>
      {show && createPortal(
        <div
          ref={refPortal}
          tabIndex={tabIndex}
          className={clsx(
            'stdPortal',
            inversed && 'stdPortal-inversed',
            takeWidthFromParent ? 'animBubbleAppear-y' : 'animBubbleAppear',
            inversed && 'animBubbleAppear-inversed',
          )}
          style={{
            position: 'absolute',
            left: `${position ? position.x : 0}px`,
            top: `${position ? position.y : 0}px`,
            zIndex: onPopup ? 200 : undefined,
            width: (takeWidthFromParent && refParent.current) ? refParent.current.clientWidth : undefined,
          }}
        >
          {typeof portalComponent === 'function' ? portalComponent(inversed) : portalComponent}
        </div>, document.getElementById('portalContainer'),
      )}
      {typeof children === 'function' ? children(inversed) : children}
    </div>
  );
};

Portal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
  portalComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
  setPortalRef: PropTypes.func,
  setParentRef: PropTypes.func,
  takeWidthFromParent: PropTypes.bool,
  show: PropTypes.bool,
  shouldInbl: PropTypes.bool,
  onPopup: PropTypes.bool,
  attachRight: PropTypes.bool,
  dependencies: PropTypes.array,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Portal.defaultProps = {
  show: null,
  takeWidthFromParent: false,
  onPopup: false,
  shouldInbl: false,
  attachRight: false,
  setPortalRef: null,
  tabIndex: null,
  setParentRef: null,
  dependencies: [],
};

export default Portal;
