import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const LoginCompteur = ({ date, withSeconds }) => {
  const { t } = useTranslation();
  return (
    <Countdown
      date={date.toJSDate()}
      intervalDelay={0}
      precision={3}
      renderer={({
        days, hours, minutes, seconds,
      }) => (
        <div className={clsx('stdCompteur', withSeconds && 'stdCompteur-withSeconds')}>
          <div>
            <div>{days < 10 ? `0${days}` : days}</div>
            <div>{t('compteur.days')}</div>
          </div>
          <div>
            <div>{hours < 10 ? `0${hours}` : hours}</div>
            <div>{t('compteur.hours')}</div>
          </div>
          <div>
            <div>{minutes < 10 ? `0${minutes}` : minutes}</div>
            <div>{t('compteur.minutes')}</div>
          </div>
          {withSeconds && (
          <div>
            <div>{seconds < 10 ? `0${seconds}` : seconds}</div>
            <div>{t('compteur.seconds')}</div>
          </div>
          )}
        </div>
      )}
    />

  );
};

LoginCompteur.propTypes = {
  withSeconds: PropTypes.bool,
  date: PropTypes.object.isRequired,
};

LoginCompteur.defaultProps = {
  withSeconds: false,
};
export default LoginCompteur;
