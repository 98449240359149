import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../components/UI/Button';
import exPhoto from '../../../img/MISK22/exPhoto.jpg';
import exPhoto2 from '../../../img/MISK22/exPhoto2.jpg';
import arrow from '../../../img/MISK22/arrow.svg';
import bigArrow from '../../../img/MISK22/bigArrow.svg';
import picGal1_1 from '../../../img/MISK22/Library/Gallery/Gallery1/01.jpg';
import picGal1_2 from '../../../img/MISK22/Library/Gallery/Gallery1/02.jpg';
import picGal1_3 from '../../../img/MISK22/Library/Gallery/Gallery1/03.jpg';
import picGal1_4 from '../../../img/MISK22/Library/Gallery/Gallery1/04.jpg';
import picGal1_5 from '../../../img/MISK22/Library/Gallery/Gallery1/05.jpg';
import picGal1_6 from '../../../img/MISK22/Library/Gallery/Gallery1/06.jpg';
import picGal1_7 from '../../../img/MISK22/Library/Gallery/Gallery1/07.jpg';
import picGal1_8 from '../../../img/MISK22/Library/Gallery/Gallery1/08.jpg';
import picGal1_9 from '../../../img/MISK22/Library/Gallery/Gallery1/09.jpg';
import picGal1_10 from '../../../img/MISK22/Library/Gallery/Gallery1/10.jpg';
import picGal1_11 from '../../../img/MISK22/Library/Gallery/Gallery1/11.jpg';
import picGal1_12 from '../../../img/MISK22/Library/Gallery/Gallery1/12.jpg';
import picGal1_13 from '../../../img/MISK22/Library/Gallery/Gallery1/13.jpg';
import picGal1_14 from '../../../img/MISK22/Library/Gallery/Gallery1/14.jpg';
import picGal1_15 from '../../../img/MISK22/Library/Gallery/Gallery1/15.jpg';
import picGal1_16 from '../../../img/MISK22/Library/Gallery/Gallery1/16.jpg';
import picGal1_17 from '../../../img/MISK22/Library/Gallery/Gallery1/17.jpg';
import picGal1_18 from '../../../img/MISK22/Library/Gallery/Gallery1/18.jpg';
import picGal1_19 from '../../../img/MISK22/Library/Gallery/Gallery1/19.jpg';
import picGal1_20 from '../../../img/MISK22/Library/Gallery/Gallery1/20.jpg';
import picGal1_21 from '../../../img/MISK22/Library/Gallery/Gallery1/21.jpg';
import picGal1_22 from '../../../img/MISK22/Library/Gallery/Gallery1/22.jpg';
import picGal1_23 from '../../../img/MISK22/Library/Gallery/Gallery1/23.jpg';
import picGal1_24 from '../../../img/MISK22/Library/Gallery/Gallery1/24.jpg';
import picGal1_25 from '../../../img/MISK22/Library/Gallery/Gallery1/25.jpg';
import picGal1_26 from '../../../img/MISK22/Library/Gallery/Gallery1/26.jpg';
import picGal1_27 from '../../../img/MISK22/Library/Gallery/Gallery1/27.jpg';
import picGal1_28 from '../../../img/MISK22/Library/Gallery/Gallery1/28.jpg';
import picGal1_29 from '../../../img/MISK22/Library/Gallery/Gallery1/29.jpg';
import picGal1_30 from '../../../img/MISK22/Library/Gallery/Gallery1/30.jpg';
import picGal1_31 from '../../../img/MISK22/Library/Gallery/Gallery1/31.jpg';
import picGal1_32 from '../../../img/MISK22/Library/Gallery/Gallery1/32.jpg';
import picGal1_33 from '../../../img/MISK22/Library/Gallery/Gallery1/33.jpg';
import picGal1_34 from '../../../img/MISK22/Library/Gallery/Gallery1/34.jpg';
import picGal1_35 from '../../../img/MISK22/Library/Gallery/Gallery1/35.jpg';
import picGal1_36 from '../../../img/MISK22/Library/Gallery/Gallery1/36.jpg';
import picGal1_37 from '../../../img/MISK22/Library/Gallery/Gallery1/37.jpg';
import picGal1_38 from '../../../img/MISK22/Library/Gallery/Gallery1/38.jpg';
import picGal1_39 from '../../../img/MISK22/Library/Gallery/Gallery1/39.jpg';
import picGal1_40 from '../../../img/MISK22/Library/Gallery/Gallery1/40.jpg';
import picGal1_41 from '../../../img/MISK22/Library/Gallery/Gallery1/41.jpg';
import picGal1_42 from '../../../img/MISK22/Library/Gallery/Gallery1/42.jpg';
import picGal1_43 from '../../../img/MISK22/Library/Gallery/Gallery1/43.jpg';
import picGal1_44 from '../../../img/MISK22/Library/Gallery/Gallery1/44.jpg';
import picGal1_45 from '../../../img/MISK22/Library/Gallery/Gallery1/45.jpg';
import clsx from "clsx";

const galleries = [
  [
    { image: picGal1_1, name: 'Photo' },
    { image: picGal1_2, name: 'Photo' },
    { image: picGal1_3, name: 'Photo' },
    { image: picGal1_4, name: 'Photo' },
    { image: picGal1_5, name: 'Photo' },
    { image: picGal1_6, name: 'Photo' },
    { image: picGal1_7, name: 'Photo' },
    { image: picGal1_8, name: 'Photo' },
    { image: picGal1_9, name: 'Photo' },
    { image: picGal1_10, name: 'Photo' },
    { image: picGal1_11, name: 'Photo' },
    { image: picGal1_12, name: 'Photo' },
  ], [
    { image: picGal1_13, name: 'Photo' },
    { image: picGal1_14, name: 'Photo' },
    { image: picGal1_15, name: 'Photo' },
    { image: picGal1_16, name: 'Photo' },
    { image: picGal1_17, name: 'Photo' },
    { image: picGal1_18, name: 'Photo' },
    { image: picGal1_19, name: 'Photo' },
    { image: picGal1_20, name: 'Photo' },
    { image: picGal1_21, name: 'Photo' },
    { image: picGal1_22, name: 'Photo' },
    { image: picGal1_23, name: 'Photo' },
  ]
];

const LibraryGallery = ({  }) => {
  const { t, i18n } = useTranslation();
  const [day, setDay] = useState(0);

  return <div className="component-library-replays">
    <nav>
      <button className={clsx(day === 0 && 'act')} type="button" onClick={() => setDay(0)}>Day 01 | Wednesday, November 29</button>
      <button className={clsx(day === 1 && 'act')} type="button" onClick={() => setDay(1)}>Day 02 | Thursday, November 30</button>
    </nav>
    {/*<nav>*/}
    {/*  <button className={clsx(room === 'plenary' && 'act')} type="button" onClick={() => setRoom('plenary')}>plenary</button>*/}
    {/*  <button className={clsx(room === 'exhibition' && 'act')} type="button" onClick={() => setRoom('exhibition')}>exhibition</button>*/}
    {/*  <button className={clsx(room === 'gcc' && 'act')} type="button" onClick={() => setRoom('gcc')}>gcc</button>*/}
    {/*  <button className={clsx(room === 'marketplace' && 'act')} type="button" onClick={() => setRoom('marketplace')}>marketplace</button>*/}
    {/*</nav>*/}

    <div className="component-library-replays-content">
      { galleries[day].map(({ image, name }, key) => (
        <div key={`${day}-${key}`}>
          <img src={image} />
          <div className="component-library-replays-content-titre">{name}</div>
        </div>
      ))}

    </div>

  </div>
};


export default LibraryGallery;
