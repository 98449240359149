import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {useSelector} from 'react-redux';
import {convertNodeToElement} from 'react-html-parser';
import {useTranslation} from 'react-i18next';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import Popup from '../../components/UI/Popup/Popup';
import Video from '../../components/UI/Video';
import InputWithIcon from '../../components/UI/Form/InputWithIcon';
import useBackButton from '../../Lib/useBackButton';
import plus from '../../../img/GHS/plusButton.svg';
import minus from '../../../img/GHS/minusButton.svg';
import ComponentTitreWithClose from "../../components/UI/ComponentTitreWithClose";

const arTextes = [
  {id: 0, question: 'ما هو منتدى مسك العالمي؟\n', reponse: 'منتدى مسك العالمي هو فعالية بارزة تقام سنويًا بجهود مؤسسة مسك، وهي منظمة غير ربحية. ويجمع المنتدى القادة والمبدعين والمفكرين الشباب مع المبتكرين العالميين لاستكشاف وتجربة طرق حديثة لقيادة التغيير وصنع الأثر.\n'},
  {
    id: 1,
    question: 'ما هو محور منتدى مسك العالمي 2022؟\n',
    reponse: '"جيل التغيير.\n'
      + '\n'
      + ' يهدف منتدى مسك العالمي لهذا العام إلى إلهام الأجيال بمبدأ الحوار لكسر الحواجز ودفع عجلة التغيير. \n'
      + '\n'
      + ' ويعمل الحوار بين الأجيال المتعددة على كسر الحواجز ومنح الإلهام لإحداث التغيير وجعل العالم مكانًا أفضل، وذلك عبر زيادة الوعي بالقيم والأسس المشتركة، كخطوة أولى لصياغة حوار مؤثر تحولي وحقيقي يُنمي الفرد والمجتمع والعالم. \n'
      + '\n'
      + ' #MGF22 is #BridgingGenerations through dialogue for positive impact."\n',
  },
  {
    id: 2,
    question: 'كيف يمكنني البقاء على تواصل بعد الفعالية؟\n',
    reponse: '"يمكنك الإطلاع على أحدث المستجدات من خلال زيارة الموقع الإلكتروني لمنتدى مسك العالمي والإشتراك في النشرة الإخبارية: https://miskglobalforum.com/\n'
      + ' أو متابعة الصفحات الرسمية لمنتدى مسك العالمي على منصات التواصل الاجتماعي: add handles | #addhashtags"\n',
  },
  {id: 3, question: 'هل سيتمكن الحاضرون الآخرون من رؤية أنشطتي خلال الفعالية؟', reponse: 'إذا كنت قد تلقيت رابط تسجيل شخصي لحضور منتدى مسك العالمي 2022، فهذا يعني أنك ستتمكن من الوصول إلى قائمة الحضور والوصول إلى المحادثة العامة. وسيقتصر وصول الحاضرين الآخرين على ما تنشره في المحادثة العامة فقط.\n'},
  {id: 4, question: 'هل أحتاج إلى تنزيل أي شيء لعرض الفعالية على جهازي المحمول؟', reponse: 'لن تحتاج إلى تنزيل تطبيق لعرض الفعالية على جهازك المحمول. كل ما عليك هو الدخول عبر رابط الفعالية على متصفح الويب على جهازك المحمول.\n'},
  {id: 5, question: 'لا أستطيع سماع المتحدثين، ماذا عليَّ أن أفعل؟', reponse: 'تأكد من وجود اتصال إنترنت سريع، وتأكد أيضًا من أن المتصفح الذي تستخدمه مُصرح له بالوصول إلى سماعات جهازك.\n'},
  {id: 6, question: 'هل يمكنني مشاركة معلومات تسجيل الدخول لحسابي مع الآخرين؟', reponse: 'حساب مكان مسك هو حساب شخصي. ولذا يُرجى عدم مشاركة بيانات تسجيل الدخول إلى حسابك مع أي شخص آخر.'},
  {id: 7, question: 'ما هي الأجهزة المدعومة؟', reponse: 'يمكنك الوصول إلى المنصة الافتراضية وعرضها والتفاعل معها من أي جهاز: الهواتف المحمولة، وأجهزة الحواسيب المحمولة، والحاسوب المكتبي والجهاز اللوحي.'},
  {id: 8, question: 'ما هو متصفح الإنترنت الأنسب لعرض الفعالية الافتراضية والتفاعل معها؟', reponse: 'نقترح استخدام أحدث نسخة من متصفح الحاسوب: فايرفوكس، وسفاري وجوجل كروم، سوف تعمل هذه المتصفحات جميعها على نحو جيد. وللحصول على تجربة مثالية، نوصي باستخدام متصفح جوجل كروم.'},
  {id: 9, question: 'ما هي مدة الفعالية؟', reponse: 'يرجى الاطلاع على جدول أعمال الفعالية بالكامل عبر النقر على أيقونة البرنامج في المنصة الافتراضية.'},
  {id: 10, question: 'ما هو جدول أعمال الفعالية؟', reponse: 'يرجى الاطلاع على جدول أعمال الفعالية بالكامل عبر النقر على أيقونة البرنامج في المنصة الافتراضية.'},
  {id: 11, question: 'من هم المتحدثون في الفعالية؟', reponse: 'يرجى الاطلاع على القائمة الكاملة للمتحدثين بالنقر على أيقونة البرنامج في المنصة الافتراضية.'},
  {id: 12, question: 'أين يمكنني مشاهدة البرنامج؟', reponse: 'انتقل إلى البث المباشر وانقر على "تشغيل" على مشغل الفيديو لكي يبدأ البث المباشر.'},
  {id: 13, question: 'هل يمكنني إعداد جدول شخصي للجلسات التي أريد حضورها؟', reponse: 'يمكنك وضع إشارة مرجعية على الجلسات التي تهمك وسيتم تمييزها بوضوح على جدول الأعمال المعروض على لوحة المتابعة الشخصية لحسابك.'},
  {id: 14, question: 'هل سأتلقى تذكيرًا قبل بداية الجلسة؟', reponse: 'في حال تحديد الجلسة كإشارة مرجعية، ستتلقى إشعارًا على لوحة التحكم الخاصة بك لتنبيهك ببدء الجلسة.'},
  {id: 15, question: 'كيف يمكنني الوصول إلى الترجمات على المنصة؟', reponse: 'ستكون الترجمة متاحة للجلسات المباشرة والمُسجَّلة. اختر لغة الترجمة من أعلى الزاوية اليمنى من شاشتك أثناء مشاهدة الجلسة.'},
  {id: 16, question: 'ماذا أفعل إذا واجهت مشكلات في تسجيل الدخول أو تسجيل حساب جديد؟', reponse: 'يرجى التأكد من تسجيل الدخول/ تسجيل حساب جديد بالطريقة الصحيحة، وإذا كنت لا تزال غير قادر على تسجيل الدخول أو تسجيل حساب جديد، يرجى التواصل عبر البريد الإلكتروني: registration@miskglobalforum.com'},
  {id: 17, question: 'هل سيتم تسجيل الجلسات المباشرة؟', reponse: 'نعم، يرجى زيارة قسم "المنتدى العالمي (الإعادة)" ضمن المكتبة. وستتم إضافة جميع مقاطع فيديو منتدى مسك العالمي 2022 بعد الفعالية!'},
  {id: 18, question: 'ما هي المدة التي ستبقى فيها المنصة الافتراضية لمنتدى مسك العالمي قيد التشغيل؟', reponse: 'المنصة الافتراضية متاحة حتى 17 نوفمبر 2022.'},
];

const InfoDesk = ({onClose, withBackground, dontOverflowQuestions}) => {
  // const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const config = useSelector((state) => state.stateDataReducer.config);
  const {t, i18n} = useTranslation();
  const [search, setSearch] = useState();
  const [openedQuestions, setOpenedQuestions] = useState([]);
  const [isViewingTutorial, setIsViewingTutorial] = useState(false);
  useBackButton(onClose);
  const url = !search ? 'faq-questions' : `faq-questions?search=${search}`;

  const [data] = useRestClient(url, null, [search]);

  // let country = 'en';
  // if (loggedUtilisateur) {
  //   [country] = (loggedUtilisateur && loggedUtilisateur.visitingCountry) ? [loggedUtilisateur.visitingCountry] : useRestClient('getCountry');
  // }

  // if (!data || !country) {
  if (!data){
    return <Chargement/>;
  }

  const transform = (node, index) => {
    if (node.type === 'tag'){
      if (node.type === 'tag' && node.name === 'a'){
        return convertNodeToElement(node, index, transform);
      }
      return null;
    }
  };

  const options = {
    decodeEntities: true,
    transform,
  };

  return (
    <div className={clsx('component-infoDesk', withBackground && 'component-infoDesk-withBackground')}>

      {/* <NotificationsMenu /> */}
      {/* <ShareMenu /> */}
      {/* <ProfileMenu /> */}

      <ComponentTitreWithClose onClick={onClose}>{t('infodesk.InfoDesk')}</ComponentTitreWithClose>

      {isViewingTutorial && (
        <Popup onClosePopup={() => setIsViewingTutorial(false)} variant="noPadding">
          <Video url={config.tutorialVideo} autoPlay variant="bigForPopup infoDesk"/>
        </Popup>
      )}
      <div className="component-infoDesk-top">

        {/* <Button iconAfter="play" onClick={() => setIsViewingTutorial(true)} variant="rounded">Watch Tutorial</Button> */}

        {/*<Button variant="main big" onClick={() => setIsViewingTutorial(true)}>*/}
        {/*  {t('infodesk.Watch Tutorial')}*/}
        {/*</Button>*/}
        <h2>{t('infodesk.Frequently asked questions')}</h2>
        {/* <div>Please search by using keywords.</div> */}
        <InputWithIcon variant="transparent" onChange={setSearch} placeholder={t('infodesk.type your keyword here')} value={search} icon="fleche"/>
      </div>

      <div className={clsx('component-infoDesk-content', dontOverflowQuestions && 'component-infoDesk-content-dontOverflowQuestions')}>
        {(i18n.language === 'ar' ? arTextes : data).map(({id, question, reponse}, index) => {
          const expanded = openedQuestions.includes(id);
          return <button type="button"
                         onClick={() => {
                           if (expanded){
                             setOpenedQuestions([...openedQuestions.filter((testId) => id !== testId)]);
                           } else{
                             setOpenedQuestions([...openedQuestions, id]);
                           }
                         }}
                         key={id}

                         className={clsx("component-infoDesk-content-question", expanded && 'act')}>

            <div><img src={expanded ? minus : plus}/></div>
            <div className="wysiwyg" dangerouslySetInnerHTML={{__html: question}}/>
            {expanded && <div className="wysiwyg" dangerouslySetInnerHTML={{__html: reponse}}/> }
          </button>
        })}
      </div>

      {/*<div className="component-infoDesk-help">*/}
      {/*  <button*/}
      {/*    type="button"*/}
      {/*    onClick={() => {*/}
      {/*      // const number = country === 'SA' ? config.whatsappNumberKsa : config.whatsappNumberOtherRegions;*/}
      {/*      window.open(`mailto:app@fmf23.com`, '_blank');*/}
      {/*      // window.open(`https://api.whatsapp.com/send?phone=${number}&text=&source=&data=&app_absent=`, '_blank');*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    TROUBLESHOOT*/}
      {/*  </button>*/}
      {/*</div>*/}
    </div>
  );
};

InfoDesk.propTypes = {
  onClose: PropTypes.func.isRequired,
  dontOverflowQuestions: PropTypes.bool,
  withBackground: PropTypes.bool,
};

InfoDesk.defaultProps = {
  dontOverflowQuestions: false,
  withBackground: false,
};

InfoDesk.defaultProps = {};
export default InfoDesk;
