import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Pagination = ({
  actPage, nbPages, changePage, total, noWrap,
}) => {
  const arPages = (Array.from({ length: nbPages }));

  return (
    <div className="stdPagination">
      <div className={clsx('stdPagination-container', noWrap && 'stdPagination-container-noWrap')}>
        <button type="button" disabled={actPage === 0} onClick={() => changePage(0)}><i className="ptl-carret" /><i className="ptl-carret" /></button>
        <button type="button" disabled={actPage === 0} onClick={() => changePage(actPage - 1)}><i className="ptl-carret" /></button>

        { arPages.length < 10 ? <>{arPages.map((el, index) => <button type="button" key={index} className={clsx(actPage === index && 'act')} onClick={() => changePage(index)}>{index + 1}</button>)}</> : (
          <>
            { actPage > 3 - 1 ? <button type="button" key={actPage - 3} onClick={() => changePage(actPage - 3)}>{actPage + 1 - 3}</button> : <div />}
            { actPage > 2 - 1 ? <button type="button" key={actPage - 2} onClick={() => changePage(actPage - 2)}>{actPage + 1 - 2}</button> : <div />}
            { actPage > 1 - 1 ? <button type="button" key={actPage - 1} onClick={() => changePage(actPage - 1)}>{actPage + 1 - 1}</button> : <div />}
            <button type="button" key={actPage} className="act" onClick={() => changePage(actPage)}>{actPage + 1}</button>
            { actPage < arPages.length - 1 ? <button type="button" key={actPage + 1} onClick={() => changePage(actPage + 1)}>{actPage + 1 + 1}</button> : <div />}
            { actPage < arPages.length - 2 ? <button type="button" key={actPage + 2} onClick={() => changePage(actPage + 2)}>{actPage + 1 + 2}</button> : <div />}
            { actPage < arPages.length - 3 ? <button type="button" key={actPage + 3} onClick={() => changePage(actPage + 3)}>{actPage + 1 + 3}</button> : <div />}
          </>
        )}

        <button type="button" disabled={actPage === nbPages - 1} onClick={() => changePage(actPage + 1)}><i className="ptl-carret" /></button>
        <button type="button" disabled={actPage === nbPages - 1} onClick={() => changePage(arPages.length - 1)}><i className="ptl-carret" /><i className="ptl-carret" /></button>
      </div>
      <div className="stdPagination-infos">
        {total} results ({arPages.length} pages)
      </div>
    </div>
  );
};

Pagination.propTypes = {
  actPage: PropTypes.number,
  noWrap: PropTypes.bool,
  total: PropTypes.number.isRequired,
  nbPages: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  noWrap: false,
  actPage: 0,
};

export default Pagination;
