import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import btnClose from '../../../img/FMF/btnClose.svg';

const ButtonClose = ({
  backgrounded, className, variant, ...props
}) => (
  <button
    type="button"
    className={clsx(
      'stdBtnClose',
      variant && `stdBtnClose-${variant}`,
      className,
      backgrounded && 'stdBtnClose-backgrounded',
    )}
    {...props}
  >
    <img src={btnClose} />

  </button>
);

ButtonClose.propTypes = {
  backgrounded: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
};

ButtonClose.defaultProps = {
  backgrounded: false,
  variant: null,
  className: '',
};

export default ButtonClose;
