import React, { useState } from 'react';
import { DateTime } from 'luxon';
import axios from 'axios';
import useInterface from '../../../../Lib/useInterface';
import useRestClient from '../../../../Lib/useRestClient';
import StaffContenu from '../../StaffContenu';
import Chargement from '../../../../components/UI/Chargement';

const PublicQuestions = () => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [deletingPublicQuestion, setDeletingPublicQuestion] = useState(null);
  const [publicQuestions] = useRestClient('/public-questions', null, [lastAction]);

  const doDeletePublicQuestions = async (publicQuestion) => {
    try {
      await axios.delete(`/staff/public-questions/${publicQuestion.id}`);
      setDeletingPublicQuestion(null);
      setLastAction(DateTime.local());
      sendMessage('The question has been deleted');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  if (!publicQuestions) {
    return <Chargement />;
  }

  return (
    <StaffContenu titre="Questions and answers">

      {publicQuestions.length ? (
        <table className="table-100 stdTable">
          <thead>
            <tr>
              <th>Question</th>
              <th>User</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {publicQuestions.map((publicQuestion) => (
              <tr key={publicQuestion.id}>
                <td>{publicQuestion.question}</td>
                <td>{`${publicQuestion.utilisateur.firstname} ${publicQuestion.utilisateur.lastname}`}</td>

                {deletingPublicQuestion === publicQuestion.id ? (
                  <td>
                    <span>Are you sure ?</span>
                    <div className="stdSplitActions stdSplitActions-confirm">
                      <button onClick={() => setDeletingPublicQuestion(null)}><i className="ptl-close2" /> no</button>
                      <button onClick={() => doDeletePublicQuestions(publicQuestion)}><i className="ptl-valid" /> yes</button>
                    </div>
                  </td>
                ) : (
                  <td className="stdSplitActions">
                    <button type="button" onClick={() => setDeletingPublicQuestion(publicQuestion.id)}>delete</button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="center">
          <div className="mid midMargin-b">No questions</div>
        </div>
      )}

    </StaffContenu>
  );
};
export default PublicQuestions;
