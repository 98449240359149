import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import twitter from '../../../img/GHS/twitter.svg';
import ln from '../../../img/GHS/ln.svg';
import youtube from '../../../img/GHS/youtube.svg';
import web from '../../../img/GHS/web.svg';
import logoWhite from '../../../img/GHS/logoWhite.svg';

const Footer = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx('stdFooter', className)}>

      <div>
        <div>
          <div>
            <img src={logoWhite} logoWhite/>
            <div>
              <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/hevolution-f/"><img src={ln} /></a>
              <a target="_blank" rel="noreferrer" href="https://twitter.com/hevolution_f"><img src={twitter} /></a>
              <a target="_blank" rel="noreferrer" href="https://hevolution.com/"><img src={youtube} /></a>
              <a target="_blank" rel="noreferrer" href="https://hevolution.com/"><img src={web} /></a>
            </div>
          </div>

          <div>
            <h2>GET IN TOUCH</h2>
            <p>For speakers related inquiries you can contact us at: <br/>
              <a href="mailto:ExecutiveOffice@globalhealthspansummit.com">ExecutiveOffice@globalhealthspansummit.com</a></p>

            <p>For any media related inquiries you can contact us at: <br/>
              <a href="mailto:Media@globalhealthspansummit.comv">Media@globalhealthspansummit.comv</a></p>
          </div>

        </div>
      </div>

      <div>
        <div>
          <div><a target="_blank" rel="noopener" href="https://hevolution.com/terms-and-conditions">Privacy Policy</a></div>
          <div>©2023 Global Healthspan Summit. All Rights Reserved.</div>
          <div>Hevolution Foundation</div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};
export default Footer;
