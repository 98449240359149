import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import useInterface from '../../Lib/useInterface';
import Button from './Button';
import * as StateAction from '../../Redux/actions/State';
import bookmarkImg from '../../../img/FMF/bookmark.svg';
import bookmarkImgChecked from '../../../img/FMF/bookmarkAct.svg';

const BookmarkIcon = ({
  programme, hasBookmarked, onChange, onBookmark, withText, onMainBack, ...props
}) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const dispatch = useDispatch();

  const bookmark = onBookmark === undefined ? (async (programmeId) => {
    try {
      const resp = await axios.post(`/programmes/${programmeId}/bookmark`);
      dispatch({ type: StateAction.SET_UTILISATEUR, loggedUtilisateur: resp.data });
      if (hasBookmarked) {
        sendMessage('The session has been unbookmarked');
      } else {
        sendMessage('The session has been bookmarked');
      }
      onChange();
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  }) : onBookmark;

  if (withText) {
    return <Button variant="transparent" onClick={() => bookmark(programme.id)} {...props} iconAfter={hasBookmarked ? 'flag' : 'flag-o'}>{hasBookmarked ? <>Un-bookmark this session</> : <>Bookmark this session</>} </Button>;
  }

  // if (onMainBack) {
  //   return <button className="stdBookmarkIcon" onClick={() => bookmark(programme.id)} {...props}>{hasBookmarked ? <img src={bookmarkImg} /> : <img src={bookmarkImg} />}</button>;
  // }

  return <button className={clsx('stdBookmarkIcon', hasBookmarked && 'act')} onClick={() => bookmark(programme.id)} {...props}>{hasBookmarked ? <img src={bookmarkImgChecked} /> : <img src={bookmarkImg} />}</button>;
};

BookmarkIcon.propTypes = {
  withText: PropTypes.bool,
  onBookmark: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  hasBookmarked: PropTypes.bool.isRequired,
  onMainBack: PropTypes.bool,
  programme: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};
BookmarkIcon.defaultProps = {
  withText: false,
  onMainBack: false,
  onBookmark: undefined,
  programme: null,
};

export default BookmarkIcon;
