import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Button from '../../components/UI/Button';
import FieldsetTextarea from '../../components/UI/Form/FieldsetTextarea';
import useInterface from '../../Lib/useInterface';

const QuestionAnswers = ({}) => {
  const { t } = useTranslation();
  const { sendRestErrorMessage, sendErrorMessage, sendMessage } = useInterface();

  return (
    <div className="component-questionAnswers">
      <h2>{t('plenary.qa.Ask a question')}</h2>

      <Formik
        initialValues={{
          question: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          try {
            await axios.post('/public-questions', values);
            sendMessage('Your text had been submitted');
            resetForm();
          } catch (err) {
            sendRestErrorMessage(err);
          }
        }}

        validationSchema={Yup.object().shape({
          question: Yup.string().required('Please provide your question'),
        })}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form className="stdForm stdForm-transparent">

            <FieldsetTextarea name="question">{t('plenary.qa.Type your question below')}:</FieldsetTextarea>
            <div className="center">
              <Button type="submit">{t('plenary.qa.Submit')}</Button>
            </div>

          </Form>
        )}
      </Formik>
    </div>
  );
};

QuestionAnswers.propTypes = {};
QuestionAnswers.defaultProps = {};
export default QuestionAnswers;
