import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import {PhotoForUtilisateur} from '../../components/UI/Photo';
import ButtonBack from '../../components/UI/ButtonBack';

const ProgramSpeaker = ({utilisateurId, onBack, forSpeakers}) => {
  const {t, i18n} = useTranslation();
  const [utilisateur] = useRestClient(`utilisateurs/${utilisateurId}`);
  if (!utilisateur){
    return <Chargement height50/>;
  }

  const getAllSessions = () => [...utilisateur.programmeSpeakers, ...utilisateur.programmeModerators].map(({programme}) => programme).sort((pa, pb) => pa.realDateDebut.valueOf() - pb.realDateDebut.valueOf());

  return (
    <div className="component-programSpeaker">
      <h1 className="stdTitle">About the speaker</h1>
      <div className="component-programSpeaker-top">

        <PhotoForUtilisateur utilisateur={utilisateur}/>
        <div>
          <h4>{`${utilisateur.firstname}`} {`${utilisateur.lastname}`}</h4>
          <div>{utilisateur.position} / {utilisateur.company}</div>
        </div>

      </div>

      {/* <hr className="stdHr" /> */}
      <div className="component-programSpeaker-bottom">
        {(!!utilisateur.description && utilisateur.description != 'NULL') ? <p className="wysiwyg" dangerouslySetInnerHTML={{__html: utilisateur.description}}/> : <br/>}

        <h2>{t('program.speakers.Session(s)')}:</h2>
        {getAllSessions().length ? (
          <div className="component-programSpeaker-session-container">{getAllSessions().map(({
                                                                                               name, jour, realDateDebut, realDateFin,
                                                                                             }, key) => (
            <div className="component-programSpeaker-session" key={key}>
              <div>DAY {jour + 1} </div>
              <div>
                <div>{realDateDebut.toFormat('HH:mm')} - {realDateFin.toFormat('HH:mm')}</div>
                <h3>{name}</h3>
              </div>
            </div>
          ))}
          </div>
        ) : <div>{t('program.speakers.No session upcoming')}</div>}

        <br/><br/><br/>


        <div className="leftAlign">
          <ButtonBack onClick={onBack}/>
        </div>
      </div>
    </div>
  );
};

ProgramSpeaker.propTypes = {
  utilisateurId: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  forSpeakers: PropTypes.bool,
};

ProgramSpeaker.defaultProps = {
  forSpeakers: false,
};
export default ProgramSpeaker;
