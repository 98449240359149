import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import logout from '../../../../img/MISK22/logout.svg';
import camera from '../../../../img/MISK22/camera.svg';
import HeaderMenu from './HeaderMenu';
import Button from '../../../components/UI/Button';
import Fieldset from '../../../components/UI/Form/Fieldset';
import useInterface from '../../../Lib/useInterface';
import avatarEmpty from '../../../../img/MISK22/profil2.svg';
import * as StateAction from '../../../Redux/actions/State';
import FieldsetSelectCountry from '../../../components/UI/Form/FieldsetSelectCountry';
import ButtonBack from '../../../components/UI/ButtonBack';

const ProfileMenu = ({ children, onLogout }) => {
  const [uploadingData, setUploadingData] = useState({
    avatar: null,
  });
  const dispatch = useDispatch();
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);

  const inputRef = useRef();

  const { sendRestErrorMessage, sendErrorMessage, sendMessage } = useInterface();
  const { t } = useTranslation();
  let closeFunc = null;

  const uploadPhoto = (files) => {
    if (!files || !files.length) {
      return;
    }
    const avatar = files[0];

    const uploadedExtension = avatar.name.split('.').pop().toLowerCase();
    if (!['jpg', 'png'].includes(uploadedExtension)) {
      sendErrorMessage(`The extension you provided (${uploadedExtension}) is not supported. Supported extensions : ${['jpg', 'png'].join(', ')}`);
    }
    setUploadingData({ ...uploadingData, avatar });
  };

  return (
    <HeaderMenu
      titre={t('profile.My Profile')}
      iconContent={children}
      setOnCloseCallback={(onCloseFunc) => {
        closeFunc = onCloseFunc;
      }}
      topContent={(
        <div className="stdHeader-headerMenu-profileMenuContent">
          <button type="button" onClick={onLogout}>
            <img src={logout} />
            {t('profile.Logout')}
          </button>
          <button type="button" onClick={() => inputRef.current && inputRef.current.click()}>
            <img src={loggedUtilisateur.avatar ? `${process.env.UPLOADS_URL}${loggedUtilisateur.avatar}` : avatarEmpty} />
            <div><img src={camera} /></div>
          </button>
        </div>
      )}
    >
      <Formik
        initialValues={loggedUtilisateur}
        onSubmit={async (values) => {
          const formData = new FormData();
          for (const key in values) {
            formData.set(key, values[key]);
          }
          for (const key in uploadingData) {
            if (uploadingData[key]) {
              formData.set(key, uploadingData[key]);
            }
          }

          try {
            formData.append('_method', 'PATCH');
            const resp = await axios.post('utilisateur', formData);
            sendMessage('Your profile had been updated');
            dispatch({ type: StateAction.SET_UTILISATEUR, loggedUtilisateur: resp.data });
            if (closeFunc) {
              closeFunc();
            }
          } catch (err) {
            sendRestErrorMessage(err);
          }
        }}

        validationSchema={Yup.object().shape({
          linkedin: Yup
            .string()
            .matches(/^https:\/\/www\.linkedin.com\/in\/(.+)$/, 'Is not in correct format, should be https://www.linkedin.com/in/xxxxx'),
        })}
      >
        {({ isSubmitting, submitForm }) => (
          <Form className="stdForm stdForm-100 stdHeader-headerMenu-profileform">
            <div className="stdHeader-headerMenu-content-inside">
              <Fieldset name="firstname">{t('profile.firstname')}</Fieldset>
              <Fieldset name="lastname">{t('profile.lastname')}</Fieldset>
              <Fieldset name="position">{t('profile.Occupation')}</Fieldset>
              <Fieldset name="company">{t('profile.Organization')}</Fieldset>
              <FieldsetSelectCountry name="country">{t('profile.Country')}</FieldsetSelectCountry>
              <Fieldset name="linkedin" placeholder="https://www.linkedin.com/in/">{t('profile.LinkedIn Profile')}</Fieldset>

              {createPortal(<input
                ref={inputRef}
                type="file"
                className="stdFileInputHidden"
                onChange={(e) => {
                  const { files } = e.target;
                  uploadPhoto(files);
                  submitForm();
                }}
              />, document.getElementById('portalContainer'))}

            </div>

            <div className="stdForm-submitLine">
              <ButtonBack type="button" variant="grey" onClick={() => !!closeFunc && closeFunc()} />
              <Button type="submit">{t('profile.Save')}</Button>
            </div>
          </Form>
        )}
      </Formik>

    </HeaderMenu>

  );
};

export default ProfileMenu;
