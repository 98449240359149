import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router';
import Chargement from '../components/UI/Chargement';
import Login from './Login/Login';
import ErrorBoundaryContenu from './ErrorBoundary/ErrorBoundaryContenu';

const UnloggedApp = () => (
  <BrowserRouter>
    <Switch>
      <Route
        exact
        path="/user-not-found"
        component={() => (
          <ErrorBoundaryContenu displayError={(
            <>
              Your account is not registered in our database, if you just registered, please try again in a minute. <br /><br />
              <div className="center"><a className="stdBtn" href={`https://account.misk.org.sa/connect/authorize?response_type=code&client_id=${process.env.SSO_CLIENTID}&redirect_uri=${process.env.API_URL}sso-callback&state=test&scope=openid profile&culture=en-Us`}>Try again</a></div>
            </>
)}
          />
        )}
      />
      <Route exact path="/chargement" component={() => <Chargement allHeight />} />
      <Route exact path="/connect-link/:lien" component={(props) => <Login {...props} />} />

      {/* <Route exact path="/pano" component={() => <Panorama />} /> */}
      <Route exact path="/:code" render={(props) => <Login {...props} />} />
      <Route exact path="/" render={() => <Login />} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>
);
export default UnloggedApp;
