import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import * as StateAction from '../../Redux/actions/State';
import StaffTab from './StaffTab';
import useInterface from '../../Lib/useInterface';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import SplitDiv from '../../components/UI/Div/SplitDiv';
import FieldsetSelect from '../../components/UI/Form/FieldsetSelect';
import { arEvenementStates } from '../../Lib/EvenementState';
import Switch from '../../components/UI/Form/Switch';

const Settings = () => {
  const { sendRestErrorMessage, sendMessage } = useInterface();

  const config = useSelector((state) => state.stateDataReducer.config);
  const dispatch = useDispatch();

  
  return (
    <StaffTab titre="Settings">
      <br />
      <br />
      <Formik
        initialValues={{
          isFinished: parseInt(config.isFinished),
          endVideoUrl: config.endVideoUrl,
          bSetSecondFooter: config.bSetSecondFooter,
          // selectedProgramPage: config.selectedProgramPage || 0,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const result = await axios.put('/staff/config', values);
            dispatch({ type: StateAction.SET_CONFIG, config: result.data });
            sendMessage('The data has been saved');
          } catch (err) {
            sendRestErrorMessage(err);
          }
          setSubmitting(false);
        }}

        validationSchema={Yup.object().shape({
          // analyticsId: Yup.string().required('You must provide this field'),
        })}
      >
        {({ isSubmitting }) => (
          <Form className="stdForm form-100">
            <SplitDiv>
              {/* <Fieldset placeholder="UA-XXXXXX-XX" name="analyticsId">Google analytics ID</Fieldset> */}
              <Fieldset placeholder="https://vimeo.com/253989945" name="endVideoUrl">Vimeo end url</Fieldset>
              <FieldsetSelect options={arEvenementStates} name="isFinished" required>Event state</FieldsetSelect>
              <div />
            </SplitDiv>

            {/*<SplitDiv>*/}
              {/*<FieldsetSelect*/}
              {/*  options={[*/}
              {/*    { value: '0', label: 'Page 1' },*/}
              {/*    { value: '1', label: 'Page 2' },*/}
              {/*  ]}*/}
              {/*  name="selectedProgramPage"*/}
              {/*  required*/}
              {/*>Program page*/}
              {/*</FieldsetSelect>*/}
              {/*<Fieldset name="bSetSecondFooter" input={(otherProps) => <Switch {...otherProps} />}>Set second footer</Fieldset>*/}
              {/*<div />*/}
            {/*</SplitDiv>*/}

            <div className="rightAlign stdForm-submitLine">
              <Button type="submit" disabled={isSubmitting}>Save changes</Button>
            </div>
          </Form>
        )}
      </Formik>
    </StaffTab>
  );
};

export default Settings;
