import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import axios from 'axios';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import useInterface from '../../Lib/useInterface';
import useSecurity from '../../Lib/useSecurity';
import usePubsub from '../../Lib/usePubsub';
import sendChat from '../../../img/GHS/arrow.svg';
import LiveSondage from '../Live/LiveSondage';
import {useTranslation} from "react-i18next";

const PublicChatLine = ({ chatMessage, onDecline, onApprove }) => {
  const { isStaff } = useSecurity();
  return (
    <div className={clsx('stdPublicChat-line', chatMessage.isStaff && 'stdPublicChat-line-isStaff')}>
      <div>
        <div>{chatMessage.utilisateur.firstname} {chatMessage.created.toFormat('LL/dd/yyyy')} {!isStaff() && !chatMessage.bAccepted && <span className="small">pending validation</span>} : </div>
        <div>{chatMessage.contenu}</div>
      </div>

      {onDecline && onApprove && (
        <div>
          {chatMessage.bAccepted !== true && <button type="button" className="stdPublicChat-btn stdPublicChat-btn-green" onClick={onApprove}><i className="ptl-valid" /></button>}
          <button type="button" className="stdPublicChat-btn stdPublicChat-btn-orange" onClick={onDecline} disabled={chatMessage.bAccepted === false}><i className="ptl-close2" /></button>
        </div>
      )}

    </div>
  );
};

PublicChatLine.propTypes = {
  onApprove: PropTypes.func,
  onDecline: PropTypes.func,
  chatMessage: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    created: PropTypes.object,
    bAccepted: PropTypes.bool,
    isStaff: PropTypes.bool,
    contenu: PropTypes.string.isRequired,
    utilisateur: PropTypes.shape({
      firstname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
PublicChatLine.defaultProps = {
  onApprove: null,
  onDecline: null,
};

const bouncedNotify = debounce(async (message) => {
  await axios.post('/staff/chat-messages/notify');
}, 15000);

const PublicChat = ({ tabbed, className, withSondage }) => {
  const { t } = useTranslation();
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [lastMessage, setLastMessage] = useState(null);
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [contenu, setContenu] = useState('');
  const { isStaff } = useSecurity();
  const dummyEnd = useRef();
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const [chatMessages] = useRestClient('chat-messages', null, [lastAction]);

  useEffect(() => {
    if (dummyEnd.current) {
      dummyEnd.current.parentNode.scrollTop = dummyEnd.current.offsetTop;
    }
  }, [chatMessages && chatMessages.length]);

  usePubsub('publicChat', () => {
    window.setTimeout(() => {
      setLastAction(DateTime.local());
    }, 2000);
  });

  if (!chatMessages) {
    return <Chargement />;
  }

  const onModerate = async (chatMessage, bAccepted) => {
    bouncedNotify(); // logs 'debounced' after 30 seconds

    try {
      if (bAccepted) {
        await axios.patch(`/chat-messages/${chatMessage.id}`, {
          bAccepted,
        });
        sendMessage('The message has been moderated');
      } else {
        await axios.delete(`/chat-messages/${chatMessage.id}`);
        sendMessage('The message has been deleted');
      }
      setLastAction(DateTime.local());
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!contenu.length) {
      return;
    }
    if (lastMessage && lastMessage.diffNow('seconds').seconds > -2) {
      sendMessage('You must wait to post a new message');
      return;
    }

    setLastMessage(DateTime.local());
    try {
      await axios.post('/chat-messages', {
        contenu,
      });
      sendMessage('The message has been sent');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    setContenu('');
  };

  const filteredChatMessages = chatMessages.filter(({ utilisateur, bAccepted }) => isStaff() || bAccepted === true || (utilisateur.id === loggedUtilisateur.id && bAccepted !== false));

  const inside = (
    <div className={clsx(
      !tabbed && className,
      'stdPublicChat',
      !tabbed && 'stdPublicChat-untabbed',
    )}
    >
      {/* <div className="center"> */}
      {/*  <h1>Public chat</h1> */}
      {/* </div> */}
      <div className="stdPublicChat-content">
        { [...filteredChatMessages].map((chatMessage) => <PublicChatLine key={chatMessage.id} chatMessage={chatMessage} onApprove={isStaff() ? (() => onModerate(chatMessage, true)) : null} onDecline={() => (isStaff() ? onModerate(chatMessage, false) : null)} />)}
        <div style={{ float: 'left', clear: 'both' }} ref={dummyEnd} />
      </div>
      <form onSubmit={onSubmit} className="stdChatInput">
        <input onChange={(e) => setContenu(e.target.value)} placeholder={t("plenary.chat.Say something")} value={contenu} />
        <button type="button" onClick={onSubmit}><img src={sendChat} /></button>
        {/* <i className="ptl-fleche" /> */}

      </form>

      { isStaff() && (
        <div className="stdPublicChat-adminBot"><button
          className="stdBtn stdBtn-small"
          type="button"
          onClick={async () => {
            await axios.post('/staff/chat-messages/notify');
          }}
        >Update
        </button>
        </div>
      )}
    </div>
  );

  if (!tabbed) {
    return inside;
  }
  return (
    <div className={clsx('stdTab', withSondage && 'relative', className)}>
      <div className="stdTab-titre">
        {/*<img src={chatIcon} />*/}
        Public live chat
      </div>
      <div className="stdTab-content">{inside}</div>
      { withSondage && <LiveSondage />}
    </div>
  );
};

PublicChat.propTypes = {
  tabbed: PropTypes.bool,
  withSondage: PropTypes.bool,
  className: PropTypes.string,
};

PublicChat.defaultProps = {
  className: null,
  tabbed: true,
  withSondage: false,
};

export default PublicChat;
