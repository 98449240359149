import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useInterface from '../../Lib/useInterface';
import LoginPopup from './LoginPopup';
import Popup from '../../components/UI/Popup/Popup';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import iconeDownload from '../../../img/FAFU/iconeDownload.svg';
import FileDropzone from '../../components/UI/FileDropzone';
import * as StateAction from '../../Redux/actions/State';
import FieldsetSelect from '../../components/UI/Form/FieldsetSelect';
import FieldsetSelectCountry from '../../components/UI/Form/FieldsetSelectCountry';

const EditProfile = ({ onClosePopup }) => {
  const imgRef = useRef();
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const [uploadingData, setUploadingData] = useState({
    avatar: null,
  });
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (uploadingData && uploadingData.avatar && imgRef.current) {
      imgRef.current.src = URL.createObjectURL(uploadingData.avatar);
    }
  }, [uploadingData && uploadingData.avatar]);

  return (
    <Popup onClosePopup={onClosePopup} className="stdEditProfilePopup">

      <div className="stdLoginPopup-titre">My profile</div>

      <Formik
        initialValues={loggedUtilisateur}
        onSubmit={async (values, { setSubmitting }) => {
          const formData = new FormData();
          for (const key in values) {
            formData.set(key, values[key]);
          }
          for (const key in uploadingData) {
            if (uploadingData[key]) {
              formData.set(key, uploadingData[key]);
            }
          }

          try {
            formData.append('_method', 'PATCH');
            const resp = await axios.post('/utilisateur', formData);
            sendMessage('Your profile has been saved');
            onClosePopup();
            dispatch({ type: StateAction.LOGIN, state: resp.data });
          } catch (err) {
            sendRestErrorMessage(err);
          }
          setSubmitting(false);
        }}

        validationSchema={Yup.object().shape({
          country: Yup.string().required('Please provide your password'),
        })}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <Form className="form-100 stdForm">

            <Fieldset type="position" placeholder="Position" name="position">Position</Fieldset>

            <FieldsetSelectCountry name="country" onPopup value={values.country}>Country</FieldsetSelectCountry>
            <fieldset className="stdFieldset">
              { loggedUtilisateur.avatar && <img ref={imgRef} src={`${process.env.UPLOADS_URL + loggedUtilisateur.avatar}?v=${loggedUtilisateur.updated.toMillis()}`} className="stdEditProfilePopup-avatar" /> }
              <label className="stdLabel" htmlFor="">Attach a picture or avatar <br />(jpeg or png, max 500 mb)</label>

              <FileDropzone
                uploadingData={() => {}}
                onDroppedFiles={(files) => {
                  setUploadingData({ avatar: files[0] });
                }}
              />
            </fieldset>

            <Button type="submit" wide>Save</Button>

          </Form>
        )}
      </Formik>
    </Popup>
  );
};

LoginPopup.propTypes = {
  onClosePopup: PropTypes.func.isRequired,
};

LoginPopup.defaultProps = {};

export default EditProfile;
