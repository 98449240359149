import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateTime from 'luxon/src/datetime';
import {useDispatch, useSelector} from 'react-redux';
import { range } from 'lodash';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import ProgramSpeakers from './ProgramSpeakers';
import ProgramProgramme from './ProgramProgramme';
import ProgramSpeaker from './ProgramSpeaker';
import BookmarkIcon from '../../components/UI/BookmarkIcon';
import Popup from '../../components/UI/Popup/Popup';
import isMobile from '../../Lib/misc/isMobile';
import star from '../../../img/MISK22/star.svg';
import gotoSession from '../../../img/FMF/gotoSession.svg';
import plusButton from '../../../img/GHS/plusButton.svg';
import minusButton from '../../../img/GHS/minusButton.svg';
import AvatarForUtilisateur from '../../components/UI/AvatarForUtilisateur';

import * as StateAction from '../../Redux/actions/State';
import axios from 'axios';
import ComponentTitreWithClose from "../../components/UI/ComponentTitreWithClose";
import Button from "../../components/UI/Button.jsx";
import useInterface from "../../Lib/useInterface.jsx";

const VIEW_SPEAKER = 'speakers';
const VIEW_AGENDA = 'agenda';

const Program = ({ onClose, withBackground }) => {
  const { t, i18n } = useTranslation();
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const [selectedProgrammeId, setSelectedProgrammeId] = useState(null);
  const [selectedUtilisateurId, setSelectedUtilisateurId] = useState(null);
  const [curDay, setCurDay] = useState(0);
  const [lastAction, setLastAction] = useState(0);
  const [arIdsExpanded, setArIdsExpanded] = useState([]);

  const { sendRestErrorMessage, sendMessage } = useInterface();
  const dispatch = useDispatch();

  const [evenement] = useRestClient('evenements/1', null, [lastAction]);

  if (!evenement) {
    return <Chargement />;
  }

  // console.log(evenement.programmes);
  const programmesThisDay = evenement.programmes.filter(({ jour }) => jour === curDay);
  const programmesBookmarked = evenement.programmes.filter(({ id }) => loggedUtilisateur && loggedUtilisateur.bookmarkedProgrammesId && loggedUtilisateur.bookmarkedProgrammesId.includes(id));

  const printLabelDay = (nDay) => {
    const labels = [
      'program.day1',
      'program.day2',
    ];
    return labels[nDay];
  };

  const getEventTitle = (name, arName) => {
    if (arName && i18n.language === 'ar') {
      return arName;
    }
    if (i18n.language === 'ar') {
      const key = `program.event.${name.replace(':', '')}`;
      if (i18n.exists(key)) {
        return t(key);
      }
    }
    return name;
  };

  if (selectedProgrammeId && isMobile()) {
    return <ProgramProgramme programmeId={selectedProgrammeId} onBack={() => setSelectedProgrammeId(null)} forMobile />;
  }

  const { recommandedPrograms } = evenement;
  const recommandedProgramsThisDay = recommandedPrograms ? programmesThisDay.filter(({ id }) => recommandedPrograms.includes(id)) : [];


  const bookmark = async (programmeId, hasBookmarked) => {
    try {
      const resp = await axios.post(`/programmes/${programmeId}/bookmark`);
      dispatch({ type: StateAction.SET_UTILISATEUR, loggedUtilisateur: resp.data });
      if (hasBookmarked) {
        sendMessage('The session has been unbookmarked');
      } else {
        sendMessage('The session has been bookmarked');
      }
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };



  return (
    <div className={clsx('component-program')}>

      <ComponentTitreWithClose onClick={onClose} moreChildren={
        <nav className="component-program-top">
          <button type="button" className={clsx(curDay === VIEW_AGENDA && 'act')} onClick={() => { setSelectedProgrammeId(null); setCurDay(VIEW_AGENDA); }}>{t('program.My agenda')}</button>

          {range(evenement.duree - 1).map((nDay) => (
            <button
              key={nDay}
              type="button"
              className={clsx(curDay === nDay && 'act')}
              onClick={() => {
                setSelectedProgrammeId(null);
                setCurDay(nDay);
              }}
            >
              {t(printLabelDay(nDay))}
            </button>
          ))}

          <button type="button" className={clsx(curDay === VIEW_SPEAKER && 'act')} onClick={() => { setSelectedProgrammeId(null); setCurDay(VIEW_SPEAKER); }}>{t('program.Speakers')}</button>
        </nav>
      }>{t('program.Program')}</ComponentTitreWithClose>



      { ![VIEW_SPEAKER, VIEW_AGENDA].includes(curDay) && !!recommandedProgramsThisDay.length && (
        <div className="component-program-recommanded">
          <h2>{t('program.Recommended')}</h2>
          <div>
            {recommandedProgramsThisDay.map(({
                                               id, jour, heure, duree, name, arName,
                                             }) => {
              const hour = DateTime.fromFormat(heure, 'HH:mm');
              const start = evenement.date.plus({ days: jour }).set({ hour: hour.toFormat('HH'), minute: hour.toFormat('mm') });
              const end = start.plus({ minutes: duree });

              return (
                <div>
                  <img src={star} />
                  <div>{start.toFormat('HH:mm')}-{end.toFormat('HH:mm')}</div>
                  <div>{getEventTitle(name, arName)}</div>
                  <BookmarkIcon
                    programme={{ id }}
                    hasBookmarked={loggedUtilisateur.bookmarkedProgrammesId && loggedUtilisateur.bookmarkedProgrammesId.includes(id)}
                    onChange={() => {
                      setLastAction(DateTime.local());
                    }}
                  />
                </div>
              );
            })}
          </div>

        </div>
      ) }

      {((() => {
        if (curDay === VIEW_SPEAKER) {
          return <ProgramSpeakers />
          //
          //   <div>
          //     <div>{curDay === VIEW_AGENDA && `Day ${jour + 1} : `} {start.toFormat('HH:mm')}-{end.toFormat('HH:mm')}</div>
          //     <div>{duree}&nbsp;{t('program.mins')}</div>
          //     { isActive && (
          //       <NavLink to="/">
          //         <img src={gotoSession} />
          //         {/*{t('program.Go to session')}*/}
          //       </NavLink>
          //     )}
          //   </div>
          //   <td>
          //     <div>
          //       <button onClick={() => setSelectedProgrammeId(id)} type="button">{getEventTitle(name, arName)}</button>
          //     </div>
          //     {!!programmeSpeakers.length && (
          //       <div>{programmeSpeakers.map(({ speaker }, key) => (
          //         <button className="component-program-avatar" onClick={() => setSelectedUtilisateurId(speaker.id)} type="button" key={key}>
          //           <AvatarForUtilisateur variant="tiny" utilisateur={speaker} />
          //           {/* {`${salutation(speaker.salutation)} ${speaker.firstname} ${speaker.lastname}`} */}
          //           {`${speaker.firstname} ${speaker.lastname}`}
          //         </button>
          //       ))}
          //       </div>
          //     )}
          //     <div className="showMobile" style={{marginTop: "0.5rem"}}>{programmeModerators.map(({ moderator }, key) => (
          //       <button className="component-program-avatar" onClick={() => setSelectedUtilisateurId(moderator.id)} type="button" key={key}>
          //         <AvatarForUtilisateur variant="tiny" utilisateur={moderator} />
          //         {/* {`${salutation(moderator.salutation)} ${moderator.firstname} ${moderator.lastname}`} */}
          //         {`${moderator.firstname} ${moderator.lastname}`}
          //       </button>
          //     ))}
          //     </div>
          //
          //   </td>
          //   <td className="hideMobile">
          //     {!!programmeModerators.length && (
          //       <div>{programmeModerators.map(({ moderator }, key) => (
          //         <button className="component-program-avatar" onClick={() => setSelectedUtilisateurId(moderator.id)} type="button" key={key}>
          //           <AvatarForUtilisateur variant="tiny" utilisateur={moderator} />
          //           {`${moderator.firstname} ${moderator.lastname}`}
          //         </button>
          //       ))}
          //       </div>
          //     )}
          //   </td>
          //   <td>
          //     <BookmarkIcon
          //       programme={{ id }}
          //       onMainBack={isActive}
          //       hasBookmarked={loggedUtilisateur.bookmarkedProgrammesId && loggedUtilisateur.bookmarkedProgrammesId.includes(id)}
          //       onChange={() => {
          //         setLastAction(DateTime.local());
          //       }}
          //     />
          //   </td>
          // </tr>
          //   <tr className="component-program-table-spacer">
          //     <td colSpan={4} />
          //   </tr>
        }


        return (
          <>
            { selectedUtilisateurId && <Popup variant="noPadding" onClosePopup={() => setSelectedUtilisateurId(null)}><ProgramSpeaker utilisateurId={selectedUtilisateurId} onBack={() => setSelectedUtilisateurId(null)} /></Popup>}
            { selectedProgrammeId && (
              <Popup variant="noPadding" onClosePopup={() => setSelectedProgrammeId(null)}><ProgramProgramme
                programmeId={selectedProgrammeId}
                onBack={() => setSelectedProgrammeId(null)}
              />
              </Popup>
            )}

            <div className="component-program-table">
              {(curDay === VIEW_AGENDA ? programmesBookmarked : programmesThisDay).filter(({ bHideInProgram }) => !bHideInProgram).map(({
                                                                                                                                          id, name, arName, description,  jour, heure, duree, programmeSpeakers, programmeModerators, currentUserHasBookmarked,
                                                                                                                                        }) => {


                // if (curDay > 1) {
                //   return (
                //     <React.Fragment key={id}>
                //       <tr className="component-program-table-bigline">
                //         <td>
                //           <div>{name}</div>
                //         </td>
                //       </tr>
                //     </React.Fragment>
                //   );
                // }

                // const isActive = id == 322;
                const hour = DateTime.fromFormat(heure, 'HH:mm');
                const start = evenement.date.plus({days: jour}).set({hour: hour.toFormat('HH'), minute: hour.toFormat('mm')});
                const end = start.plus({minutes: duree});

                const isActive = (DateTime.local() > start && DateTime.local() < end);

                const expanded = arIdsExpanded.includes(id);
                const hasBookmarked = loggedUtilisateur.bookmarkedProgrammesId && loggedUtilisateur.bookmarkedProgrammesId.includes(id);

                return <div key={id} className={clsx(isActive && 'active', expanded && 'expanded')}>
                  <div>
                    <div>{curDay === VIEW_AGENDA && `Day ${jour + 1} : `} {start.toFormat('HH:mm')}-{end.toFormat('HH:mm')}</div>
                    <button type="button" onClick={() => {
                      if (arIdsExpanded.includes(id)) {
                        setArIdsExpanded(arIdsExpanded.filter((arId) => arId !== id));
                      }
                      else {
                        setArIdsExpanded([...arIdsExpanded, id]);
                      }
                    }
                    }><img src={expanded ? minusButton : plusButton} /></button>
                  </div>
                  <div>{getEventTitle(name, arName)}</div>


                  {/*<td>*/}
                  {/*  <div>{duree}&nbsp;{t('program.mins')}</div>*/}
                  {/*  { isActive && (*/}
                  {/*    <NavLink to="/">*/}
                  {/*      <img src={gotoSession} />*/}
                  {/*    </NavLink>*/}
                  {/*  )}*/}
                  {/*</td>*/}
                  {/*<td>*/}
                  {/*  <BookmarkIcon*/}
                  {/*    programme={{ id }}*/}
                  {/*    onMainBack={isActive}*/}
                  {/*    hasBookmarked={loggedUtilisateur.bookmarkedProgrammesId && loggedUtilisateur.bookmarkedProgrammesId.includes(id)}*/}
                  {/*    onChange={() => {*/}
                  {/*      setLastAction(DateTime.local());*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</td>*/}

                  {description && <p className="component-program-table-description wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />}


                  {programmeSpeakers.length + programmeModerators.length > 0 && <div className="component-program-table-speakersModerators">
                    <div>
                      <h2>Speakers</h2>
                      <div>{programmeSpeakers.map(({speaker}, key) => {
                        return (
                          <button className="component-program-avatar" onClick={() => setSelectedUtilisateurId(speaker.id)} type="button" key={key}>
                            <AvatarForUtilisateur utilisateur={speaker}/>
                            {/* {`${salutation(speaker.salutation)} ${speaker.firstname} ${speaker.lastname}`} */}
                            <div>
                              <div>
                                {`${speaker.firstname} ${speaker.lastname}`}
                              </div>
                              <div>{speaker.position}</div>
                            </div>

                          </button>
                        );
                      })}
                      </div>
                    </div>
                    <div>
                      <h2>Moderators</h2>
                      <div>{programmeModerators.map(({moderator}, key) => (
                        <button className="component-program-avatar" onClick={() => setSelectedUtilisateurId(moderator.id)} type="button" key={key}>
                          <AvatarForUtilisateur variant="tiny" utilisateur={moderator}/>
                          {/* {`${salutation(moderator.salutation)} ${moderator.firstname} ${moderator.lastname}`} */}
                          {`${moderator.firstname} ${moderator.lastname}`}
                        </button>
                      ))}
                      </div>
                    </div>
                  </div>
                  }

                  {expanded && <div className="component-program-table-addToAgenda"><Button onClick={() => { bookmark(id, hasBookmarked)}} variant="transparent small">

                    {hasBookmarked ? "Remove from my agenda" : "Add to my agenda" }
                  </Button></div> }


                  {/*<td className="hideMobile">*/}
                  {/*  {!!programmeModerators.length && (*/}
                  {/*    <div>{programmeModerators.map(({ moderator }, key) => (*/}
                  {/*      <button className="component-program-avatar" onClick={() => setSelectedUtilisateurId(moderator.id)} type="button" key={key}>*/}
                  {/*        <AvatarForUtilisateur variant="tiny" utilisateur={moderator} />*/}
                  {/*        {`${moderator.firstname} ${moderator.lastname}`}*/}
                  {/*      </button>*/}
                  {/*    ))}*/}
                  {/*    </div>*/}
                  {/*  )}*/}
                  {/*</td>*/}
                </div>
              })}
            </div>
          </>
        );
      }))()}
    </div>
  );
};



export default Program;
