import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DateTime from 'luxon/src/datetime';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Button from '../../components/UI/Button';
import ProgramSpeaker from './ProgramSpeaker';
import SpeakerDesc from '../../components/UI/SpeakerDesc';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import BookmarkIcon from '../../components/UI/BookmarkIcon';
import ButtonBack from '../../components/UI/ButtonBack';
import * as StateAction from '../../Redux/actions/State';
import useInterface from '../../Lib/useInterface';

const DateForProgramme = ({ programme }) => {
  if (!programme.realDateDebut || !programme.duree) {
    return null;
  }
  return (
    <div>
      <div>{programme.realDateDebut.toFormat('DD')}</div>
      <div>{programme.realDateDebut.toFormat('HH:mm')} - {programme.realDateFin.toFormat('HH:mm ZZZZ')}</div>
      {/* <div>{programme.duree} mins</div> */}
    </div>
  );
};

const ProgramProgramme = ({ programmeId, onBack, forMobile }) => {
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [selectedUtilisateurId, setSelectedUtilisateurId] = useState(null);

  const { sendRestErrorMessage, sendMessage } = useInterface();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);

  const [programme] = useRestClient(`programmes/${programmeId}`, null, [lastAction]);
  if (!programme) {
    return <Chargement height50 />;
  }

  if (selectedUtilisateurId) {
    return <ProgramSpeaker utilisateurId={selectedUtilisateurId} onBack={() => setSelectedUtilisateurId(null)} />;
  }

  const hasBookmarked = loggedUtilisateur.bookmarkedProgrammesId && loggedUtilisateur.bookmarkedProgrammesId.includes(programmeId);

  const bookmark = (async () => {
    try {
      const resp = await axios.post(`/programmes/${programmeId}/bookmark`);
      dispatch({ type: StateAction.SET_UTILISATEUR, loggedUtilisateur: resp.data });
      if (hasBookmarked) {
        sendMessage('The session has been unbookmarked');
      } else {
        sendMessage('The session has been bookmarked');
      }
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  });

  return (
    <div className={clsx('component-programProgramme', forMobile && 'component-programProgramme-forMobile')}>


      <h1 className="stdTitle">{t('program.program.About this session')}</h1>
      <div className="component-programProgramme-top">
        <DateForProgramme programme={programme} />

        <div>
          <h2>{i18n.language === 'ar' && programme.arName ? programme.arName : programme.name}</h2>
        </div>
        {/*  <BookmarkIcon */}
        {/*  className="inbl big smallMargin-r" */}
        {/*  hasBookmarked={loggedUtilisateur.bookmarkedProgrammesId && loggedUtilisateur.bookmarkedProgrammesId.includes(programme.id)} */}
        {/*  onChange={() => { */}
        {/*    setLastAction(DateTime.local()); */}
        {/*  }} */}
        {/*  programme={programme} */}
        {/* /> */}

      </div>
      {/* <BookmarkIcon */}
      {/*  withText */}
      {/*  small */}
      {/*  hasBookmarked={loggedUtilisateur.bookmarkedProgrammesId && loggedUtilisateur.bookmarkedProgrammesId.includes(programme.id)} */}
      {/*  className="floatRight hideTablet" */}
      {/*  onChange={() => { */}
      {/*    setLastAction(DateTime.local()); */}
      {/*  }} */}
      {/*  programme={programme} */}
      {/* /> */}

      <div className="component-programProgramme-bot">

        {/* <p className="wysiwyg" dangerouslySetInnerHTML={{ __html: 'vierium Cursuss mori in placidus aetheres!' }} /> */}
        <p className="wysiwyg" dangerouslySetInnerHTML={{ __html: i18n.language === 'ar' && programme.arName ? programme.arDescription : programme.description }} />

        <div>
          <div>
            <h3>{t('program.program.Speakers')}</h3>
            <div className="component-programProgramme-bot-el-container">
              { programme.programmeSpeakers.length ? programme.programmeSpeakers.map(({ speaker }, key) => (
                <div key={key} onClick={() => setSelectedUtilisateurId(speaker.id)} className="component-programProgramme-bot-el">
                  <SpeakerDesc small utilisateur={speaker} />
                </div>
              )) : <div className="component-programProgramme-bot-el-container-allWidthEl">{t('program.program.No speakers at this time')}</div>}
            </div>
          </div>
          <div>
            <h3>{t('program.program.Moderator')}</h3>
            <div className="component-programProgramme-bot-el-container">
              { programme.programmeModerators.length ? programme.programmeModerators.map(({ moderator }, key) => (
                <div key={key} onClick={() => setSelectedUtilisateurId(moderator.id)} className="component-programProgramme-bot-el">
                  <SpeakerDesc small utilisateur={moderator} />
                </div>
              )) : <div className="component-programProgramme-bot-el-container-allWidthEl">{t('program.program.No moderators at this time')}</div>}
            </div>
          </div>
        </div>

        <div className="component-programProgramme-btns">
          <ButtonBack onClick={onBack} />
          <Button onClick={bookmark}>{ hasBookmarked ? 'Remove from my agenda' : 'Add to my agenda'}</Button>
        </div>

      </div>

    </div>
  );
};

ProgramProgramme.propTypes = {
  programmeId: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  forMobile: PropTypes.bool,
};

ProgramProgramme.defaultProps = {
  forMobile: false,
};
export default ProgramProgramme;
