import React from 'react';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import live from '../../../img/GHS/menuIcons/live.svg';
import program from '../../../img/GHS/menuIcons/program.svg';
import infoDesk from '../../../img/GHS/menuIcons/infoDesk.svg';
import library from '../../../img/GHS/menuIcons/library.svg';
import networking from '../../../img/GHS/menuIcons/networking.svg';

const MenuWindow = () => {
  const { t } = useTranslation();
  return (
    <div className="stdMainSpace-menu">

      <NavLink to="/" exact>
        <div><img src={live} />
          <div>live</div>
        </div>
      </NavLink>

      <NavLink to="/program">
        <div><img src={program} />
          <div>program</div>
        </div>
      </NavLink>
      <NavLink to="/info-desk">
        <div><img src={infoDesk} />
          <div>{t('menuWindow.Info desk')}</div>
        </div>
      </NavLink>
      {/*<NavLink to="/library">*/}
      {/*  <div><img src={library} />*/}
      {/*    <div>Library</div>*/}
      {/*  </div>*/}
      {/*</NavLink>*/}
      <NavLink to="/networking">
        <div><img src={networking} />
          <div>Networking</div>
        </div>
      </NavLink>
    </div>
  );
};

export default MenuWindow;
