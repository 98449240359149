import React, { useState } from 'react';
import clsx from 'clsx';

export default (WrappedComponent) => ({
  onDrop, onDragOver, onDropFileCallback, ...props
}) => {
  const [hasDraggableOver, setHasDraggableOver] = useState(false);

  return (
    <WrappedComponent
      {...props}
      onDropFileCallback={onDropFileCallback || undefined}
      data-hasdraggableover={hasDraggableOver}
      // data-hasdraggableover={onDrop ? hasDraggableOver : undefined}
      onDragOver={(event) => {
        event.preventDefault();

        if (!hasDraggableOver) {
          setHasDraggableOver(true);
        }
        if (onDragOver) {
          onDragOver();
        }
        event.stopPropagation();
      }}

      onDrop={(event) => {
        event.preventDefault();
        if (onDrop && event.dataTransfer.getData('text') && event.dataTransfer.getData('text').length > 0) {
          try {
            onDrop(JSON.parse(event.dataTransfer.getData('text')));
            event.dataTransfer.clearData();
          } catch (e) {
            // Dropimpossible;
          }
        } else if (event.dataTransfer.items.length && onDropFileCallback) {
          let filesAr = [];
          for (let i = 0; i < event.dataTransfer.items.length; i++) {
            if (event.dataTransfer.items[i].kind === 'file') {
              filesAr = [...filesAr, event.dataTransfer.items[i].getAsFile()];
            }
          }

          onDropFileCallback(filesAr);
          event.preventDefault();
        }
        setHasDraggableOver(false);
        event.stopPropagation();

        window.setTimeout(() => {
          document.querySelectorAll('[data-hasdraggableover]').forEach((elem) => {
            elem.setAttribute('data-hasdraggableover', false);
          });
        }, 100);
      }}

      onDragLeave={(event) => {
        event.preventDefault();
        const rect = event.currentTarget.getBoundingClientRect();

        if (event.clientX > rect.left + rect.width || event.clientX < rect.left
          || event.clientY > rect.top + rect.height || event.clientY < rect.top) {
          setHasDraggableOver(false);
        }
      }}
    />
  );
};
