import React from 'react';
import PropTypes from 'prop-types';

const ErrorBoundaryContenu = ({ error, displayError }) => (
  <div className="component-error">
    { displayError ? <h2>{displayError}</h2> : (
      <>
        <h2>An error has occured</h2>
        <p>Our teams had been noticed of this problem</p>
      </>
    )}
  </div>
);

ErrorBoundaryContenu.propTypes = {
  error: PropTypes.object,
  displayError: PropTypes.any,
};

ErrorBoundaryContenu.defaultProps = {
  error: null,
  displayError: null,
};

export default ErrorBoundaryContenu;
