import React, { useRef } from 'react';
import {
  Redirect, Route, Switch, useHistory,
} from 'react-router';

import { useSelector } from 'react-redux';
import InfoDesk from '../InfoDesk/InfoDesk';
import Program from '../Program/Program';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import About from '../About/About';
import SocialMediaWall from '../SocialMediaWall/SocialMediaWall';
import useInterface from '../../Lib/useInterface';
import { PAGE_LIVE, PAGE_LOBBY } from '../Header/Header';
import WhoIsWhoV2 from '../WhoIsWho/WhoIsWhoV2';
import Library from '../Library';
import UnloggedNetworking from "../WhoIsWho/UnloggedNetworking";
import WhoIsWho from "../WhoIsWho/WhoIsWho";

const BottomWindow = () => {
  const history = useHistory();
  const contentRef = useRef();
  const currentSceneInPanorama = useSelector((state) => state.interfaceDataReducer.currentSceneInPanorama);
  const { changePage } = useInterface();
  history.listen((location2) => {
    if (!['/', '/chargement', '/live'].includes(location2.pathname)) {
      changePage(PAGE_LOBBY);
      window.setTimeout(() => {
        if (contentRef.current) {
          const newTop = contentRef.current.getBoundingClientRect().top + document.getElementById('root').scrollTop - 80;
          if (document.getElementById('root').scrollTop < newTop) {
            document.getElementById('root').scrollTo({
              top: newTop,
              left: 0,
              behavior: 'smooth',
            });
          }
        }
      }, 150);
    } else if (location2.pathname === '/live') {
      changePage(PAGE_LIVE);
      document.getElementById('root').scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else if (location2.pathname === '/') {
      changePage(PAGE_LOBBY);
      if (currentSceneInPanorama === 'scene_the_stage') {
        window.krpanoCommand('lobby');
      }
    }
  });

  return (
    <div className="stdMainSpace-botWindow" ref={contentRef}>
      <Switch>
        <Route
          exact
          path="/about-fasion-futures"
          render={() => (
            <ErrorBoundary><About onClose={() => {
              history.push('/');
            }}
            />
            </ErrorBoundary>
          )}
        />
        <Route
          exact
          path="/program"
          render={() => (
            <ErrorBoundary><Program
              onClose={() => {
                history.push('/');
              }}
            />
            </ErrorBoundary>
          )}
        />
        <Route
          exact
          path="/info-desk"
          render={() => (
            <ErrorBoundary> <InfoDesk
              onClose={() => {
                history.push('/');
              }}
            />
            </ErrorBoundary>
          )}
        />
        <Route
          exact
          path="/networking"
          render={() => (
            <ErrorBoundary><WhoIsWho
              onClose={() => {
                history.push('/');
              }}
            />
            </ErrorBoundary>
          )}
        />


        {/*<Route exact path="/library" render={() => (<ErrorBoundary><Library onClose={() => { history.push('/'); }} />             </ErrorBoundary>)} />*/}


        {/* <Route exact path="/chat" render={() => (<ErrorBoundary><Chat onClose={() => { history.push('/'); }} withBackground /></ErrorBoundary>)} /> */}

        {/*<Route exact path="/social" render={() => (<ErrorBoundary><SocialMediaWall onClose={() => { history.push('/'); }} withBackground /> </ErrorBoundary>)} />*/}

        <Route exact path="/live" />
        <Redirect from="*" to="/" />
      </Switch>

    </div>
  );
};

export default BottomWindow;
