import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ButtonClose from '../../components/UI/ButtonClose';

const UnloggedNetworking = ({ onClose, withBackground }) => (
  <div className={clsx('component-unloggedNetworking', withBackground && 'component-unloggedNetworking-withBackground')}>
    {onClose && <ButtonClose className="floatRight" onClick={onClose} />}
    <h1 className="stdTitle mainDark">Networking</h1>

    <h2>SAY HI!</h2>
    <p>Start a new message to connect with other virtual attendees at the Future Minerals Forum.</p>

    <a href="https://futuremineralsforum.v4.eventnetworking.com/login" target="_blank" rel="noopener" className="stdBtn">START NETWORKING</a>
  </div>
);

UnloggedNetworking.propTypes = {
  onClose: PropTypes.func,
  withBackground: PropTypes.bool,
};

UnloggedNetworking.defaultProps = {
  onClose: null,
  withBackground: false,
};

export default UnloggedNetworking;
