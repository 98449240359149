import React, { useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import StaffContenu from './StaffContenu';
import Button from '../../components/UI/Button';
import Fieldset from '../../components/UI/Form/Fieldset';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import SondageStatut, { getLabelForSondageStatut } from '../../Lib/Enums/SondageStatut';
import useInterface from '../../Lib/useInterface';
import ClickEditText from '../../components/UI/ClickEditText';
import ButtonAjouter from '../../components/UI/ButtonAjouter';
import swapArrayElements from '../../Lib/misc/swapArrayElements';
import toFixedOrRound from '../../Lib/misc/toFixedOrRound';
import usePubsub from '../../Lib/usePubsub';

const DragHandle = SortableHandle(() => <div className="stdHandle" />);

const SortableItem = SortableElement(({
  sondageReponse, reponse, canBeModified, id, setFieldValue, values, sendErrorMessage,
}) => (
  <tr>
    {canBeModified && <td><DragHandle /></td>}
    <td>
      <ClickEditText
        value={reponse}
        onChange={canBeModified ? ((newRep) => {
          setFieldValue('reponses', [...values.reponses.slice(0, id), newRep, ...values.reponses.slice(id + 1)]);
        }) : null}
      />
    </td>
    {sondageReponse && <td>{toFixedOrRound(100 * sondageReponse.percent, 1)}% ({sondageReponse.count} votes)</td>}
    {canBeModified && (
    <td className="rightAlign">
      <button
        type="button"
        onClick={() => {
          if (values.reponses.length < 3) {
            sendErrorMessage('You cannot delete the last answer, please edit it');
          } else {
            setFieldValue('reponses', [...values.reponses.slice(0, id), ...values.reponses.slice(id + 1)]);
          }
        }}
      ><i className="ptl-close smallMargin-r" />
      </button>
    </td>
    )}
  </tr>
));

const SortableList = SortableContainer(({
  sondageReponses, canBeModified, values, setFieldValue, sendErrorMessage,
}) => (
  <tbody>
    {values.reponses.map((reponse, key) => (
      <SortableItem
        key={key + reponse}
        reponse={reponse}
        sondageReponse={sondageReponses ? sondageReponses.answers[key] : null}
        canBeModified={canBeModified}
        setFieldValue={setFieldValue}
        sendErrorMessage={sendErrorMessage}
        id={key}
        index={key}
        values={values}
      />
    ))}
  </tbody>
));

const EditPoll = ({
  match, onCancel, onSuccess,
}) => {
  const sondageId = match && match.params && match.params.id;

  const history = useHistory();
  const [lastAction, setLastAction] = useState(DateTime.local());
  const { sendRestErrorMessage, sendErrorMessage, sendMessage } = useInterface();

  usePubsub(`pollsAnswers/${sondageId}`, () => {
    sendMessage('A new vote to the poll had been received');
    setLastAction(DateTime.local());
  });

  const [sondage] = sondageId ? useRestClient(`sondages/${sondageId}`, null, [lastAction]) : [{
    question: 'Question',
    statut: SondageStatut.STOPPED,
    reponses: [
      'Answer 1',
      'Answer 2',
      'Answer 3',
      'Answer 4',
    ],
  }];
  if (!sondage) {
    return <Chargement />;
  }

  const swapStatut = async (sondage, newStatut) => {
    let label = null;
    switch (newStatut) {
      case SondageStatut.ACTIVE: {
        label = 'The poll had been activated';
        break;
      }
      default:
      case SondageStatut.STOPPED: {
        label = 'The poll had been stopped';
        break;
      }
      case SondageStatut.ARCHIVED: {
        label = 'The poll had been archived';
        break;
      }
    }

    try {
      await axios.post(`/staff/sondages/${sondage.id}/changeStatut`, {
        newStatut,
      });
      setLastAction(DateTime.local());
      sendMessage(label);
    } catch (err) {
      sendRestErrorMessage(err);
    }
  };

  const canBeModified = sondage.statut === SondageStatut.STOPPED;

  return (
    <StaffContenu titre="Add a poll" rightContent={<Button onClick={() => history.push('/polls')}>Back to poll list</Button>}>

      <Formik
        initialValues={sondage}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (sondageId) {
              await axios.patch(`/staff/sondages/${sondageId}`, values);
              sendMessage('The poll has been modified');
            } else {
              await axios.post('/staff/sondages', values);
              sendMessage('The poll has been created');
            }
            onSuccess();
          } catch (err) {
            sendRestErrorMessage(err);
          }
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          question: Yup.string().required('You must provide this field'),
          reponses: Yup.array().min(2).required('You must provide this field'),
        })}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form className="stdForm form-100">

            <Fieldset placeholder="question" name="question" autoFocus disabled={!canBeModified}>Question</Fieldset>

            {canBeModified && (
            <ButtonAjouter
              className="floatRight smallMargin-b"
              small
              onClick={() => {
                if (values.reponses.length >= 8) {
                  sendErrorMessage('You cannot set more than 8 answers');
                } else {
                  setFieldValue('reponses', [...values.reponses, 'New answer']);
                }
              }}
            >Add an answer
            </ButtonAjouter>
            )}
            <div className="clearFloat">&nbsp;</div>
            <table className="stdTable table-100 bigMargin-b">

              <SortableList
                values={values}
                setFieldValue={setFieldValue}
                canBeModified={canBeModified}
                sondageReponses={sondage.sondageReponses}
                sendErrorMessage={sendErrorMessage}
                helperClass="stdTable-draggingHelper"
                onSortEnd={({ oldIndex, newIndex }) => {
                  if (sondage.statut === SondageStatut.ACTIVE) {
                    sendErrorMessage('You cannot swap the answers of an active poll');
                    return;
                  }
                  if (sondage.sondageReponses) {
                    sendErrorMessage('You cannot swap the answers cause some people had already answer to the poll');
                    return;
                  }
                  setFieldValue('reponses', swapArrayElements(values.reponses, oldIndex, newIndex));
                }}
                useDragHandle
              />
            </table>

            { sondage.sondageReponses && <div className="bold midMargin-b mid">Total of voters : {sondage.sondageReponses.totalCount}</div>}

            <div className="flex flex-spaceBetween">

              { sondageId && (
              <div>
                <div className="inbl bold mid">Current statut : {getLabelForSondageStatut(sondage.statut)}</div>
                { sondage.statut === SondageStatut.ACTIVE && <Button variant="danger" className="smallMargin-l" small onClick={() => swapStatut(sondage, SondageStatut.STOPPED)}>Stop poll</Button> }
                { sondage.statut === SondageStatut.STOPPED && <><Button variant="valid" className="smallMargin-l" small onClick={() => swapStatut(sondage, SondageStatut.ACTIVE)}>Launch poll</Button><Button variant="danger" className="smallMargin-l" small onClick={() => swapStatut(sondage, SondageStatut.ARCHIVED)}>Archive</Button></> }
                { sondage.statut === SondageStatut.ARCHIVED && <Button variant="valid" className="smallMargin-l" small onClick={() => swapStatut(sondage, SondageStatut.STOPPED)}>Un-archive poll</Button> }
              </div>
              ) }

              <div>
                <Button onClick={onCancel} variant="transparent">Back to list</Button>
                <Button type="submit" disabled={isSubmitting}>Save and quit</Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </StaffContenu>
  );
};

EditPoll.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  match: PropTypes.object,
};
EditPoll.defaultProps = {
  match: null,
};

export default EditPoll;
