import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import btnClose from '../../../img/GHS/btnClose.svg';

const ComponentTitreWithClose = ({
  children, className, variant, onClick, moreChildren, ...props
}) => (
  <div
    className={clsx(
      'stdComponentTitreWithClose',
      variant && `stdComponentTitreWithClose-${variant}`,
      className,
    )}
    {...props}
  >
    <div>{children}</div>
    <button onClick={onClick} type="button"><img src={btnClose} /></button>

    {moreChildren && <div>{moreChildren}</div>}

  </div>
);

ComponentTitreWithClose.propTypes = {
  backgrounded: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
  moreChildren: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

ComponentTitreWithClose.defaultProps = {
  backgrounded: false,
  variant: null,
  moreChildren: null,
  className: '',
};

export default ComponentTitreWithClose;
