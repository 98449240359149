import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import leftArrow from '../../img/GHS/leftArrow.svg';
import reports from '../../img/GHS/library/reports.svg';
import videos from '../../img/GHS/library/videos.svg';
import gallery from '../../img/GHS/library/gallery.svg';

import LibraryPartners from './Library/LibraryPartners';
import LibraryReports from './Library/LibraryReports';
import LibraryReplays from './Library/LibraryReplays';
import ComponentTitreWithClose from "../components/UI/ComponentTitreWithClose.jsx";
import LibraryGallery from "./Library/LibraryGallery.jsx";

const Library = ({withBackground, onClose}) => {
  const {t} = useTranslation();
  const [page, setPage] = useState(null);
  return (
    <div className={clsx('component-library', withBackground && 'component-library-withBackground')}>
      <ComponentTitreWithClose onClick={onClose}>{!!page && <button type="button" onClick={() => setPage(null)}><img src={leftArrow} style={{width : "1rem", verticalAlign: "middle", marginRight: "1rem"}}/></button>}Library</ComponentTitreWithClose>

      {
        (() => {
          switch(page){
            case 'reports':{
              return (
                <div className="component-library-content">
                  <h2>Reports</h2>
                  <LibraryReports/>
                </div>
              );
            }
            case 'replay':{
              return (

                <div className="component-library-content">
                  <h2>Videos</h2>
                  <LibraryReplays/>
                </div>

              );
            }
            case 'gallery':{
              return (
                 <div className="component-library-content">
                  <h2 className="stdTitle">Gallery</h2>
                  <LibraryGallery />
                </div>
              );
            }

            default:
            case null:{
              return (
                <>


                  <div className="component-library-menu">
                    <button type="button" onClick={() => setPage('reports')}>
                      <img src={reports}/>
                      <p>Reports</p>
                    </button>
                    <button type="button" onClick={() => setPage('replay')}>
                      <img src={videos}/>
                      <p>Videos</p>
                    </button>
                    <button type="button" onClick={() => setPage('gallery')}>
                      <img src={gallery}/>
                      <p>Gallery</p>
                    </button>
                  </div>
                </>
              );
            }
          }
        })()
      }

    </div>
  );
};

Library.propTypes = {
  onClose: PropTypes.func.isRequired,
  withBackground: PropTypes.bool,
};

Library.defaultProps = {
  withBackground: false
};

export default Library;
