import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import SA from 'svg-country-flags/svg/sa.svg';
import EN from 'svg-country-flags/svg/gb.svg';
import ES from 'svg-country-flags/svg/es.svg';
import CH from 'svg-country-flags/svg/ch.svg';

const LANG_EN = 'EN';
const LANG_AR = 'AR';
const LANG_CH = 'CH';
const LANG_ES = 'ES';

const MenuLang = ({ val, setLang, textVersion }) => (
  <div className="stdMenuLang">
    <button type="button" className={clsx(LANG_EN === val && 'act')} onClick={() => setLang(LANG_EN)}>
      { textVersion ? <p>EN</p> : <img src={EN} />}
    </button>
    <button type="button" className={clsx(LANG_AR === val && 'act')} onClick={() => setLang(LANG_AR)}>
      { textVersion ? <p>AR</p> : <img src={SA} /> }
    </button>
    <button type="button" className={clsx(LANG_ES === val && 'act')} onClick={() => setLang(LANG_ES)}>
      { textVersion ? <p>ES</p> : <img src={ES} /> }
    </button>
    <button type="button" className={clsx(LANG_CH === val && 'act')} onClick={() => setLang(LANG_CH)}>
      { textVersion ? <p>CH</p> : <img src={CH} /> }
    </button>
    {/*<button type="button" className={clsx(LANG_CH === val && 'act')} onClick={() => setLang(LANG_CH)}>*/}
    {/*  { textVersion ? <p>CH</p> : <img src={CH} />}*/}
    {/*</button>*/}
  </div>
);

MenuLang.propTypes = {
  textVersion: PropTypes.bool.isRequired,
  val: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired,
};
MenuLang.defaultTypes = {
  textVersion: true,
};

export { LANG_AR, LANG_CH, LANG_EN, LANG_ES };
export default MenuLang;
