import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import download from '../../../img/GHS/menuIcons/program.svg';
import pdffile1 from '../../../img/FMF/Library/Reports/1-GWM Resources Fund Investor Presentation.pdf';
import pdffile2 from '../../../img/FMF/Library/Reports/2-Saudi Wared Group Profile.pdf';
import pdffile3 from '../../../img/FMF/Library/Reports/3-SRK Consulting Corporate profile.pdf';
import pdffile4 from '../../../img/FMF/Library/Reports/4-We inspire a new generation of safe, sustainaible, and intelligent mining.pdf';
import pdffile5 from '../../../img/FMF/Library/Reports/5-Industrialization of the Mineral Value Chain.pdf';
import pdffile6 from '../../../img/FMF/Library/Reports/6-Tackling the Responsible Sourcing Challenge.pdf';
import pdffile7 from '../../../img/FMF/Library/Reports/7- Future Minerals Forum –2022 reflection.pdf';

const LibraryReports = ({ }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="component-library-reports component-library-borderedContent">
        <a href={pdffile1} target="_blank" rel="noreferrer">
          <img src={download} />
          <div>GWM Resources Fund investor presentation</div>
        </a>
        <a href={pdffile2} target="_blank" rel="noreferrer">
          <img src={download} />
          <div>Profile – Saudi Wared Group</div>
        </a>
        <a href={pdffile3} target="_blank" rel="noreferrer">
          <img src={download} />
          <div>SRK Consulting Corporate profile</div>
        </a>
        <a href={pdffile4} target="_blank" rel="noreferrer">
          <img src={download} />
          <div>We Inspire a new generation of safe, sustainable, and intelligent mining</div>
        </a>
        <a href={pdffile5} target="_blank" rel="noreferrer">
          <img src={download} />
          <div>Industrialization of the Mineral Value Chain</div>
        </a>
        <a href={pdffile6} target="_blank" rel="noreferrer">
          <img src={download} />
          <div>Tackling the Responsible Sourcing Challenge</div>
        </a>
        <a href={pdffile7} target="_blank" rel="noreferrer">
          <img src={download} />
          <div>Future Minerals Forum –2022 reflection</div>
        </a>
      </div>
    </>
  );
};

LibraryReports.propTypes = {
};

LibraryReports.defaultProps = {};

export default LibraryReports;
