import React from 'react';
import PropTypes from 'prop-types';
import Video from '../../components/UI/Video';

const LibraryVideo = ({ url, children, onChoose }) => <Video url={url} onClickIntercept={() => onChoose({ url, titre: children })}>{children}</Video>;

LibraryVideo.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  onChoose: PropTypes.func.isRequired,
};

LibraryVideo.defaultProps = {};

export default LibraryVideo;
