import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header, { PAGE_CONCLAVE } from './Header/Header';
import MainSpace from './MainSpace/MainSpace';
import useSecurity from '../Lib/useSecurity';
import StaffSpace from './StaffSpace/StaffSpace';
import Cookies from './Cookies';
import SwitchUserPannel from './SwitchUserPannel';
import useInterface from '../Lib/useInterface';

let connection = null;

const LoggedApp = () => {
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const currentPage = useSelector((state) => state.interfaceDataReducer.currentPage);
  const { changePage } = useInterface();
  const { isStaff, isConcierge } = useSecurity();

  useEffect(() => {
    if (!loggedUtilisateur) {
      return;
    }
    window.setTimeout(() => {
      connection = new signalR.HubConnectionBuilder()
        .withUrl(`https://ptldemo.azurewebsites.net/traffichub?token=${loggedUtilisateur.token}`)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      async function start() {
        try {
          await connection.start();
          // console.log('SignalR Connected.');
        } catch (err) {
          // console.log(err);
          setTimeout(start, 5000);
        }
      }
      connection.onclose(start);
      start();
    }, 1000);
  }, [loggedUtilisateur]);

  const beforeLogout = async () => {
    await connection.stop();
    // console.log('SignalR Disconnected');
  };

  return (
    <BrowserRouter>

      { (() => {
        if (isStaff()) {
          return <StaffSpace beforeLogout={beforeLogout} />;
        }
        return <MainSpace beforeLogout={beforeLogout} />;
      })()}

      <SwitchUserPannel />
      <Cookies />

    </BrowserRouter>
  );
};

export default LoggedApp;
