import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import HeaderMenu from './HeaderMenu';
import * as StateAction from '../../../Redux/actions/State';

const NotificationsMenu = ({ children }) => {
  const { t } = useTranslation();
  const { hasNotification } = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const dispatch = useDispatch();
  const { iframeToken } = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const url = iframeToken && `${process.env.DATARIUM_CHAT_URL}/my-notifications?mytoken=${iframeToken}`;

  return (
    <HeaderMenu
      iconContent={children}
      titre={t('notifications.My Notifications')}
      className="stdHeader-headerMenu-notificationsMenu"
      onOpen={async () => {
        if (hasNotification) {
          const resp = await axios.get('/utilisateurs/setNotif?type=no_notif');
          dispatch({ type: StateAction.LOGIN, state: resp.data });
        }
      }}
    >
      <iframe src={url} frameBorder="0" enablejsapi="1" />
    </HeaderMenu>
  );
};

export { NotificationsMenu };
