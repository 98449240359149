import '../less/main.less';

import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './Redux/store';
import App from './screens/App';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";


ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'),
);

