import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import arrowLeft from '../../../img/FMF/arrowLeft.svg';

const ButtonBack = ({
  backgrounded, onClick, className, variant, ...props
}) => (
  <button
    type="button"
    className={clsx(
      'stdBtnBack',
      variant && `stdBtnBack-${variant}`,
      className,
      backgrounded && 'stdBtnBack-backgrounded',
    )}
    onClick={onClick}
    {...props}
  >
    <img src={arrowLeft} />

  </button>
);

ButtonBack.propTypes = {
  onClick: PropTypes.func.isRequired,
  backgrounded: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
};

ButtonBack.defaultProps = {
  backgrounded: false,
  variant: null,
  className: '',
};

export default ButtonBack;
