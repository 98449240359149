import { combineReducers, createStore, applyMiddleware } from 'redux';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
import interfaceDataReducer from './reducers/interfaceDataReducer';
import stateDataReducer from './reducers/stateDataReducer';
import { loadState, saveState } from './localStorage';

const reducers = combineReducers({
  interfaceDataReducer,
  stateDataReducer,
});

let store = null;

const shouldLoadSentry = (PRODUCTION || PRODUCTION === 'true');

if (shouldLoadSentry) {
  const config = {
    environment: process.env.SITENAME,
    dsn: 'https://ce69a441cf7f449694f8962a42a318b9@o1176900.ingest.sentry.io/6275237',

    release: `ptl_frontend@${VERSION}`,
  };
  Sentry.init(config);

  store = createStore(reducers, loadState(), applyMiddleware(createSentryMiddleware(Sentry, {})));
} else {
  store = createStore(reducers, loadState(), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
}

store.subscribe(() => {
  const state = store.getState();
  saveState(state);

  if (shouldLoadSentry && state.stateDataReducer && state.stateDataReducer.loggedUtilisateur) {
    Sentry.setUser(state.stateDataReducer.loggedUtilisateur);
  }
});

export default store;
