import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ButtonClose from '../../components/UI/ButtonClose';
import back from '../../../img/FAFU/aboutFond.svg';

const About = ({ onClose }) => (
  <div className={clsx('component-about')}>

    {onClose && <ButtonClose className="floatRight" onClick={onClose} />}
    <h1 className="stdTitle main">About</h1>

    <p>Lively, engaging, and anchored in the sharing of practical expertise, Fashion
      Futures will see fashion’s digital disruptors, design mavericks and inspiring leaders
      from the fashion scene globally – discussing their careers, engaging in debates,
      and participating in hands-on workshops, masterclasses, and Q&A sessions.</p>
    {/*<p className="component-about-bordered">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis. </p>*/}
    <img className="component-about-back" src={back} />

    <div className="clearFloat">&nbsp;</div>
  </div>
);

About.propTypes = {
  onClose: PropTypes.func.isRequired,
};

About.defaultProps = {
};

export default About;
