import React from 'react';
import sendChat from '../../../img/GAS/sendChat.svg';

const Chat = () => (
  <div className="stdDelegateChat">
    <div className="stdDelegateChat-delegateList">
      <button type="button" className="stdDelegateChat-delegateList-element">
        <div>Delegate name</div>
        <div>Ongoing chat with person...</div>
        <div>5 min ago</div>
      </button>

      <button type="button" className="stdDelegateChat-delegateList-element stdDelegateChat-delegateList-element-act">
        <div>Delegate name</div>
        <div>Ongoing chat with person...</div>
        <div>9:30</div>
      </button>

    </div>

    <div className="stdDelegateChat-chat">
      <div className="stdDelegateChat-chat-titre">Chat</div>
      <div className="stdDelegateChat-chat-content">
        <ul className="stdDelegateChat-chat-message-container">
          <li>
            <div className="stdDelegateChat-chat-message">
              Hello! <br />
              Delegate message appears here..

              <svg viewBox="0 0 11.9 19.9">
                <path d="M11.9,19.9L0,19.9c0,0,11.9-6.7,11.9-19.9" />
              </svg>
              <div className="stdDelegateChat-chat-message-time">9:30 am</div>
            </div>
          </li>
          <li>
            <div className="stdDelegateChat-chat-message">
              Hello! <br />
              Delegate message appears here..

              <svg viewBox="0 0 11.9 19.9">
                <path d="M11.9,19.9L0,19.9c0,0,11.9-6.7,11.9-19.9" />
              </svg>
              <div className="stdDelegateChat-chat-message-time">9:30 am</div>
            </div>
          </li>
          <li>
            <div className="stdDelegateChat-chat-message stdDelegateChat-chat-message-answer">
              Hello! <br />
              Delegate message appears here..

              <svg viewBox="0 0 11.9 19.9">
                <path d="M11.9,19.9L0,19.9c0,0,11.9-6.7,11.9-19.9" />
              </svg>
              <div className="stdDelegateChat-chat-message-time">9:30 am</div>
            </div>
          </li>

        </ul>

        <form className="stdChatInput">
          <input placeholder="Say something" />

          <button type="submit">
            {/*<img src={sendChat} />*/}
          </button>
        </form>
      </div>
    </div>
  </div>
);

Chat.propTypes = {};

export default Chat;
