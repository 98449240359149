import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ButtonClose from '../../components/UI/ButtonClose';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import AvatarForUtilisateur from '../../components/UI/AvatarForUtilisateur';
import linkedIn from '../../../img/MISK22/linkedinWhite.svg';
import loupe from '../../../img/MISK22/loupe.svg';
import WhoIsWhoV2FilterDiv from './WhoIsWhoV2FilterDiv';
import Pagination from '../../components/UI/Pagination';
import getArCountryName from '../../Lib/misc/getArCountryName';

const countries = require('i18n-iso-countries');

const WhoIsWhoV2 = ({ onClose, withBackground }) => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [interest, setInterest] = useState([]);
  const [country, setCountry] = useState([]);
  const [occupation, setOccupation] = useState([]);

  const url = occupation.length + country.length + interest.length === 0 ? `utilisateurs-matching?search=${search}&page=${page}` : `utilisateurs-matching?occupation=${occupation.join(',')}&country=${country.join(',')}&interest=${interest.join(',')}&search=${search}&page=${page}`;
  const [utilisateursData] = useRestClient(url, null, []);

  if (!utilisateursData) {
    return <Chargement height50 />;
  }

  return (
    <div className={clsx('component-whoiswhoV2', withBackground && 'component-whoiswhoV2-withBackground')}>
      {onClose && <ButtonClose className="stdBtnClose-grey" onClick={onClose} />}
      <h1 className="stdTitle">Networking</h1>

      <div className="component-whoiswhoV2-filters">
        <div className="stdInputContainer stdInputContainer-iconLeft">
          <img src={loupe} />
          <input type="text" className="stdInput stdInput-transparent" placeholder={t('whoIsWho.Search')} value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
        <WhoIsWhoV2FilterDiv interest={interest} setInterest={setInterest} country={country} setCountry={setCountry} occupation={occupation} setOccupation={setOccupation} />
      </div>
      <table className="component-whoiswhoV2-table">
        <thead>
          <tr>
            <th>{t('whoIsWho.Delegate Name')}</th>
            <th>{t('whoIsWho.Match')} %</th>
            <th className="hideTablet">{t('whoIsWho.Occupation')}</th>
            <th className="hideTablet">{t('whoIsWho.Organization')}</th>
            <th>{t('whoIsWho.Country')}</th>
            <th>{t('whoIsWho.LinkedIn')}</th>
          </tr>
        </thead>
        <tbody>
          {utilisateursData.results.map(({ matching, utilisateur }) => (
            <tr key={utilisateur.id}>
              <td>
                <AvatarForUtilisateur utilisateur={utilisateur} />
                {utilisateur.firstname} {utilisateur.lastname}
              </td>
              <td>{(matching * 100).toFixed(0)}%</td>
              <td className="hideTablet">{utilisateur.occupation && t(`occupations.${utilisateur.occupation}`)}</td>
              <td className="hideTablet">{utilisateur.company}</td>
              <td>{i18n.language === 'ar' ? getArCountryName(utilisateur.country) : countries.getName(utilisateur.country, i18n.language)}</td>
              <td>
                {utilisateur.linkedin && utilisateur.linkedin !== 'NULL' ? <a href={utilisateur.linkedin} target="blank" rel="noreferrer"><img src={linkedIn} /></a> : <a href="#" className="disabled" target="blank" rel="noreferrer"><img src={linkedIn} /></a>}

              </td>
            </tr>
          ))}

        </tbody>
      </table>

      <div className="center bigPadding-y">
        <Pagination noWrap nbPages={utilisateursData.pagesCount} changePage={setPage} actPage={page} total={utilisateursData.total} nbPerPage={50} />
      </div>
    </div>
  );
};

WhoIsWhoV2.propTypes = {
  onClose: PropTypes.func,
  withBackground: PropTypes.bool,
};

WhoIsWhoV2.defaultProps = {
  onClose: null,
  withBackground: false,
};

export default WhoIsWhoV2;
