import React, { useState } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import useInterface from '../../Lib/useInterface';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import FieldsetFile from '../../components/UI/Form/FieldsetFile';
import WysiwygTextarea from '../../components/UI/Form/Wysiwyg/WysiwygTextarea';
import InputHour from '../../components/UI/Form/InputHour';
import Switch from '../../components/UI/Form/Switch';
import SplitDiv from '../../components/UI/Div/SplitDiv';

const EditSpeaker = ({ utilisateurId, onCancel, onSuccess }) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [uploadingData, setUploadingData] = useState({
    avatar: null,
  });
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [utilisateur] = useRestClient(`staff/utilisateurs/${utilisateurId}`, null, [lastAction]);

  if (!utilisateur) {
    return <Chargement />;
  }

  return (
    <Formik
      initialValues={utilisateur}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        const formData = new FormData();
        for (const key in values) {
          formData.set(key, values[key]);
        }
        for (const key in uploadingData) {
          if (uploadingData[key]) {
            formData.set(key, uploadingData[key]);
          }
        }

        try {
          formData.append('_method', 'PATCH');
          await axios.post(`/staff/utilisateurs/${utilisateurId}`, formData);
          sendMessage('The speaker had been updated');
          onSuccess();
        } catch (err) {
          sendRestErrorMessage(err);
        }
        setSubmitting(false);
      }}

      validationSchema={Yup.object().shape({
        // description: Yup.string().required('You must provide this field'),
      })}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form className="stdForm form-100">

          <SplitDiv>
            <FieldsetFile withPhoto={!!utilisateur.avatar} name="avatar" value={utilisateur.avatar} onUpload={(avatar) => setUploadingData({ ...uploadingData, avatar })} extensions={['jpg', 'png']}>Upload avatar</FieldsetFile>
            <Fieldset name="bCacherSpeaker" input={(otherProps) => <Switch {...otherProps} />}>Hide speaker</Fieldset>
          </SplitDiv>

          <Fieldset name="description" input={(otherProps) => <WysiwygTextarea {...otherProps} />}>Description</Fieldset>
          <Fieldset name="arDescription" input={(otherProps) => <WysiwygTextarea {...otherProps} />}>Description (Ar)</Fieldset>

          <div className="rightAlign stdForm-submitLine">
            <Button onClick={onCancel} variant="transparent">Back to speakers list</Button>
            <Button type="submit" disabled={isSubmitting}>Save and quit</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditSpeaker.propTypes = {
  utilisateurId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default EditSpeaker;
