import i18n from 'i18next';
import { en, ar } from './locales';


i18n.init({
  debug: false,
  returnEmptyString: false,
  lng: 'en',
  fallbackLng: 'en',
  defaultNS: 'translation',

  // react: {
  //   wait: true,
  // },
});

i18n.addResourceBundle('en', 'translation', en);
i18n.addResourceBundle('ar', 'translation', ar);

export default i18n;
