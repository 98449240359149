import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateTime from 'luxon/src/datetime';
import MenuLang, { LANG_AR, LANG_CH, LANG_EN, LANG_ES } from '../../components/UI/MenuLang';

const LiveYoutube = ({ channelIndex, allChannels }) => {
  const [lang, setLang] = useState(LANG_EN);
  const container = useRef();
  const [isFullScreenState, setIsFullScreenState] = useState(false);

  let langIndex = 0;
  if (lang === LANG_AR) {
    langIndex = 1;
  }
  if (lang === LANG_ES) {
    langIndex = 2;
  }
  if (lang === LANG_CH) {
    langIndex = 3;
  }


  let dateChannelIndex = 0;
  if(DateTime.local().toFormat('dd') > 29){
    dateChannelIndex = 1;
  }
  const url = allChannels[channelIndex].channels[dateChannelIndex][langIndex];


  return (
    <div className="component-live">
      <div className={clsx('component-live-iframe', isFullScreenState && 'component-live-iframe-fullScreen')} ref={container}>

        <MenuLang val={lang} setLang={setLang} textVersion />
        <iframe src={url} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
      </div>
    </div>
  );
};

LiveYoutube.propTypes = {
  channelIndex: PropTypes.number.isRequired,
  allChannels: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    langs: PropTypes.array.isRequired,
    channels: PropTypes.array.isRequired,
  })).isRequired,
};
export default LiveYoutube;
