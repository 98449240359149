import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../components/UI/Button';
import useInterface from '../../Lib/useInterface';
import * as StateAction from '../../Redux/actions/State';

const LiveSondage = ({ currentSondage }) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [curAnswerIndex, setCurAnswerIndex] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const idSondagesRepondus = useSelector((state) => state.stateDataReducer.idSondagesRepondus);

  if (!currentSondage) {
    return null;
  }

  if (idSondagesRepondus && idSondagesRepondus.includes(currentSondage.id)) {
    return <div className="component-liveSondage component-liveSondage-answeredAll">You successfully answered to the polls!</div>;
  }

  const doVote = async (answerIndex) => {
    try {
      const resp = await axios.post(`/sondages/${currentSondage.id}/vote`, {
        answerIndex,
      });
      setCurAnswerIndex(null);

      if (answerIndex !== null) {
        sendMessage(t('plenary.polls.Your vote had been received'));
      }

      dispatch({ type: StateAction.SET_SONDAGES_REPONDUS, idSondagesRepondus: resp.data });
    } catch (err) {
      sendRestErrorMessage(err);
    }
  };

  return (
    <div className="component-liveSondage">
      <div>{currentSondage.question}</div>
      <div>
        {currentSondage.reponses.map((reponse, index) => (
          <button type="button" onClick={() => setCurAnswerIndex(index)} className={clsx(index === curAnswerIndex && 'act')} key={index}>{reponse}</button>
        ))}
      </div>
      <Button wide onClick={() => doVote(curAnswerIndex)}>{t('plenary.polls.Submit')}</Button>
    </div>
  );
};

LiveSondage.propTypes = {
  currentSondage: PropTypes.object,
};
LiveSondage.defaultProps = {
  currentSondage: null,
};
export default LiveSondage;
