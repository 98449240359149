import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ButtonClose from '../../components/UI/ButtonClose';

const SocialMediaWall = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <div className={clsx('component-socialMedia')}>

      {onClose && <ButtonClose className="stdBtnClose-grey" onClick={onClose} />}
      <h1 className="stdTitle">{t('socialWall.Social Wall')}</h1>

      <div className="component-socialMedia-top">
        <div>#MGF2022</div>
        <div>#BridgingGenerations</div>
      </div>
      <div className="component-socialMedia-contenu">
         <iframe allowFullScreen id="wallsio-iframe" src="https://my.walls.io/Misk?nobackground=1&amp;show_header=0" loading="lazy" title="Misk"></iframe>
      </div>

    </div>
  );
};

SocialMediaWall.propTypes = {
  onClose: PropTypes.func.isRequired,
};

SocialMediaWall.defaultProps = {
};

export default SocialMediaWall;
