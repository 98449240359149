import React, { useState } from 'react';
import { DateTime } from 'luxon';
import axios from 'axios';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import useInterface from '../../../../Lib/useInterface';
import useRestClient from '../../../../Lib/useRestClient';
import Chargement from '../../../../components/UI/Chargement';
import StaffContenu from '../../StaffContenu';
import ButtonAjouter from '../../../../components/UI/ButtonAjouter';
import truncate from '../../../../Lib/misc/truncate';
import Fieldset from '../../../../components/UI/Form/Fieldset';
import Button from '../../../../components/UI/Button';
import Switch from '../../../../components/UI/Form/Switch';
import SplitDiv from '../../../../components/UI/Div/SplitDiv';
import FieldsetTextarea from '../../../../components/UI/Form/FieldsetTextarea';

const MgfVideoEdit = ({ mgfVideo, onCancel, onSuccess }) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();

  return (
    <Formik
      initialValues={mgfVideo || {
        name: '',
        isEnabled: true,
        desc1: '',
        desc2: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          if (mgfVideo && mgfVideo.id) {
            await axios.patch(`/staff/mgf-videos/${mgfVideo.id}`, values);
            sendMessage('The virtual classroom has been saved');
            onSuccess();
          } else {
            await axios.post('/staff/mgf-videos', values);
            sendMessage('The virtual classroom has been saved');
            onSuccess();
          }
        } catch (err) {
          sendRestErrorMessage(err);
        }
        setSubmitting(false);
      }}

      validationSchema={Yup.object().shape({
        name: Yup.string().required('You must provide this field'),
        video: Yup.string().required('You must provide this field'),
      })}
    >
      {({ values, isSubmitting }) => (
        <Form className="stdForm form-100">

          <SplitDiv>
            <Fieldset placeholder="Title" name="name">Title</Fieldset>
            <Fieldset name="isEnabled" input={(otherProps) => <Switch {...otherProps} />}>Is enabled </Fieldset>
          </SplitDiv>

          <Fieldset name="video" placeholder="https://www.youtube.com/watch?v=XXXXXXXXXXX">Youtube/vimeo video</Fieldset>
          <Fieldset name="desc1" placeholder="">Description</Fieldset>
          <FieldsetTextarea name="desc2" placeholder="">Text</FieldsetTextarea>

          <div className="rightAlign stdForm-submitLine">
            <Button variant="transparent" onClick={onCancel}>Cancel</Button>
            <Button type="submit" disabled={isSubmitting}>Save changes</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

MgfVideoEdit.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  mgfVideo: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};
MgfVideoEdit.defaultProps = {
  mgfVideo: null,
};
const Videos = () => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [deletingMgfVideo, setDeletingMgfVideo] = useState(null);
  const [editingMgfVideo, setEditingMgfVideo] = useState(null);
  const [adding, setAdding] = useState(false);
  const [mgfVideos] = useRestClient('/mgf-videos', null, [lastAction]);

  const doDeleteMgfVideos = async (mgfVideo) => {
    try {
      await axios.delete(`/staff/mgf-videos/${mgfVideo.id}`);
      setDeletingMgfVideo(null);
      setLastAction(DateTime.local());
      sendMessage('The partner has been deleted');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  if (!mgfVideos) {
    return <Chargement />;
  }

  if (editingMgfVideo || adding) {
    return (
      <StaffContenu titre="Virtual Classrooms"><MgfVideoEdit
        mgfVideo={adding ? null : editingMgfVideo}
        onCancel={() => {
          setEditingMgfVideo(null);
          setAdding(false);
        }}
        onSuccess={() => {
          setEditingMgfVideo(null);
          setAdding(false);
          setLastAction(DateTime.local());
        }}
      />
      </StaffContenu>
    );
  }

  return (
    <StaffContenu titre="Virtual Classrooms" rightContent={!!mgfVideos.length && <ButtonAjouter className="floatRight" onClick={() => setAdding(true)}>Add more</ButtonAjouter>}>

      {mgfVideos.length ? (
        <table className="table-100 stdTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Is enabled</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {mgfVideos.map((mgfVideo) => (
              <tr key={mgfVideo.id}>
                <td>{truncate(mgfVideo.name, 40)}</td>
                <td>{mgfVideo.isEnabled ? 'Yes' : 'No'}</td>

                {deletingMgfVideo === mgfVideo.id ? (
                  <td>
                    <span>Are you sure ?</span>
                    <div className="stdSplitActions stdSplitActions-confirm">
                      <button onClick={() => setDeletingMgfVideo(null)}><i className="ptl-close2" /> no</button>
                      <button onClick={() => doDeleteMgfVideos(mgfVideo)}><i className="ptl-valid" /> yes</button>
                    </div>
                  </td>
                ) : (
                  <td className="stdSplitActions">
                    <button type="button" onClick={() => setEditingMgfVideo(mgfVideo)}>edit</button>
                    <button type="button" onClick={() => setDeletingMgfVideo(mgfVideo.id)}>delete</button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="center">
          <div className="mid midMargin-b">No video has been uploaded</div>
          <ButtonAjouter onClick={() => setAdding(true)}>Add a video</ButtonAjouter>
        </div>
      )}

    </StaffContenu>
  );
};
export default Videos;
