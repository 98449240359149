import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import useRestClient from '../../Lib/useRestClient';
import EvenementState from '../../Lib/EvenementState';
import logo from '../../../img/GHS/logo.svg';
import trait from '../../../img/GHS/trait.svg';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import Chargement from '../../components/UI/Chargement';
import LoginCompteur from './LoginCompteur';
import Footer from '../MainSpace/Footer';
import LanguageButton from '../Header/LanguageButton';

const Login = ({ match }) => {
  const { t } = useTranslation();
  const recaptchaRef = useRef();
  const [error, setError] = useState(null);

  const [config] = useRestClient('/config');

  // const shouldDisplayLogin = (config && parseInt(config.isFinished) !== EvenementState.UNSTARTED) || (match && match.params && match.params.code === 'testMISK22!!!');

  const shouldDisplayLogin = (!config || parseInt(config.isFinished) !== EvenementState.UNSTARTED) || (match && match.params && match.params.code === 'testGHS!!!');
  // const shouldDisplayLogin = false;

  const tryConnect = async (password, captcha, setSubmitting = null) => {
    try {
      await axios.post('login_check', {
        username: password,
        password,
        captcha,
      });
      window.location.href = '/chargement';
    } catch (err) {
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (err.response.data.code === 444) {
        setError('Your ip is not whitelisted');
      } else {
        setError('Please verify your password');
      }

      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
  };

  useEffect(() => {
    if (match && match.params.lien) {
      tryConnect(match.params.lien, 'ed59bfc238f82a8e3f75464e557c2bad43f6a431af2e2d634a07d2e7622f0d42');
    }
  }, []);

  const onChangeCapcha = (val) => {
  };

  if (!config) {
    return <Chargement />;
  }
  // shouldDisplayLogin = false;

  return (
    <div className="component-connexion">


      <Formik
        initialValues={{
          password: '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const captchaVal = await recaptchaRef.current.executeAsync();
            setError(null);
            await tryConnect(values.password, captchaVal);
            setSubmitting(false);
          } catch (err) {
            console.log('captcha err', err);
            setSubmitting(false);
          }
        }}

        validationSchema={Yup.object().shape({
          password: Yup.string().required('Please provide your password'),
        })}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form>

            {!shouldDisplayLogin ? (
              <div className="component-connexion-compteur">


                <div className="component-connexion-compteur-top">
                  <img src={logo} />
                  <div>29 - 30 NOVEMBER , 2023 <br/>RIYADH, SAUDI ARABIA</div>
                </div>
                <div className="component-connexion-compteur-bottom">

                  <h1>
                    <div><img src={trait} /></div>
                    <div>GLOBAL</div>
                    <div>HEALTHSPAN</div>
                    <div>SUMMIT</div>
                  </h1>
                  <h2>Igniting a Healthy Revolution</h2>
                  <LoginCompteur
                    withSeconds
                    date={DateTime.fromFormat('29/11/2023 08:45', 'dd/LL/yyyy HH:mm', {
                      zone: 'UTC+3',
                    })}
                  />

                  {/*<a href="https://registration.arabchinaconference.com/virtual" target="_blank" rel="noopener">If you did not register yet, please click here</a>*/}
                </div>
              </div>
            ) : (
              <div className="component-connexion-ouverte">
                <div><img src={logo} /></div>

                <div>
                  <h2>Welcome!</h2>
                  <p>To enter the Virtual Platform, please enter below the access code you received by email.</p>
                  <p>Each delegate is responsible for safeguarding their own password to ensure secure access to the virtual platform.</p>

                  <div className="form-100">
                    <Fieldset type="password" placeholder="Access code" name="password" onIconClic={() => handleSubmit()} />
                    <a href="" target="_blank" rel="noopener">Forgot your access code?</a>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey="6Ldcq9EZAAAAAI7TW8UyizDAMUnD1L230q4S5OBz"
                      onChange={onChangeCapcha}
                    />
                    <Button wide type="submit">Sign in</Button>
                    {isSubmitting && <Chargement withoutText />}
                    {error && <div className="error smallMargin-t center">{error}</div>}
                    <a href="https://registration.arabchinaconference.com/virtual" target="_blank" rel="noopener">If you did not register yet, please click here</a>
                  </div>


                </div>
              </div>
            )}


            <Footer />
          </Form>
        )}
      </Formik>


    </div>
  );
};

Login.propTypes = {
  match: PropTypes.object,
};

Login.defaultProps = {
  match: null,
};
export default Login;
