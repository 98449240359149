import * as InterfaceAction from '../actions/Interface';

const defaultInterfaceState = {
  cookies: false,
  currentPage: null,
  currentSceneInPanorama: null,
  isTesting: false,
  language: 'en',
};

function interfaceDataReducer(state = defaultInterfaceState, action) {
  switch (action.type) {
    case InterfaceAction.SET_PAGE: {
      return { ...state, currentPage: action.page };
    }
    case InterfaceAction.HIDE_COOKIES: {
      return { ...state, cookies: true };
    }
    case InterfaceAction.SET_CURRENT_SCENE_IN_PANORAMA: {
      return { ...state, currentSceneInPanorama: action.scene };
    }
    case InterfaceAction.SWITCH_TESTING: {
      return { ...state, isTesting: !state.isTesting };
    }
    case InterfaceAction.SET_LANGUAGE: {
      return { ...state, language: action.language };
    }
    default:
      return state;
  }
}

export { defaultInterfaceState };
export default interfaceDataReducer;
